<template>
  

    <div  class="card rounded-lg shadow border border-white bg-white h-100 mb-0 pb-3 overflow-auto" >
        <div class="card-header bg-transparent">
                 
               
    
    <div class="row">
    
    
        <div class="col-4">
            
            <h5 class="ml-2 text-secondary font-weight-light"> <span class="fa fa-file-alt"></span> Bon De Charge : <span class="ml-2 text-success"> {{nomBon}} </span> </h5>
            
            <div class="mt-1 ml-2 d-inline text-secondary font-weight-light">Client : <span class="d-inline text-info"> {{nClient}} </span>  </div>
            <div class="mt-1 ml-2 d-inline text-secondary font-weight-light">Projet : <span class="d-inline text-info"> {{nProjet}} </span>  </div>
            
    
    
        </div>
    
        <div class="col">
             
    
             
    
                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                <i class="fa fa-home fa-2x"></i>
                    </b-button>
    
    
    
                    <b-button  v-if="d_get_gst_proj_valider_bn_charg==true &&is_valid==0" pill size="sm"  class="m-2 float-right" variant="success" @click="clickValiderBon(1)">
                        <i class="fa fa-check"></i>
                        <span> Valider</span> 
                    </b-button>
    
                    <b-button  v-if="d_get_gst_proj_valider_bn_charg==true &&is_valid==1" pill size="sm"  class="m-2 float-right" variant="secondary" @click="clickValiderBon(0)">
                        <i class="fa fa-check"></i>
                        <span> Devalider</span> 
                    </b-button>
                    <b-button v-if="is_valid==0" pill size="sm"  class="m-2 float-right" variant="danger" @click="del_BonClick">
                        <i class="fa fa-trash-alt"></i>
                        <span> Supprimer</span> 
                    </b-button>
    

                    <b-button   pill size="sm"  class="m-2 float-right" variant="warning" @click="export_pdf_charge">
                        <i class="fa fa-print"></i>
                        <span> Bon De Charge</span> 
                    </b-button>
                    
    
        </div>
    
    </div>
    
    
    
    
    
    
        </div>
    
    
    
    
        <div class="row card-body h-100 p-0 m-0">
    <div class="p-0">
            <b-button v-if="is_valid==0" v-b-toggle.collap-categ.collap-articl.collap-form size="lg" class=" ml-0 mr-0 pl-1 pr-1 float-left text-secondary" variant="link">
            <i class="fa fa-bars"></i>                
            </b-button> 
    </div>
      
                    <b-collapse v-if="is_valid==0" id="collap-categ" visible  class="col-2 h-100 mr-3 ml-0 mt-2 p-0">
    
                                <div class="card border-success h-100 overflow-auto">
                                <div class="card-header  bg-success border-success p-1">
                                                <b-form-input v-model="searchCateg" class="border-info" id="search-cat" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                                               
                                                <b-button  @click="AfficherTousArts" size="sm"  class="ml-1 mb-1 mt-1 p-0 text-white text-decoration-none" variant="link">
                                                <i class="fa fa-list-alt mr-1"></i>  
                                                Tous Les Articles              
                                                </b-button> 
                                </div>
    
                                <b-list-group flush >
                                <b-list-group-item  v-for="item in filterCategorie" :key="item.id" button class="p-1" variant="success" @click="cat_click(item.id)">
                                        {{ item.nom }} 
                                        <p> <small>{{item.description}}</small> </p>
                                </b-list-group-item>
    
                                </b-list-group>
                                </div>
    
    
    
                        
                    </b-collapse>
    
                    <b-collapse v-if="is_valid==0" id="collap-articl" visible  class="col-2 h-100 mr-0 mt-2 p-0">
    
    
                                <div class="card border-info h-100  overflow-auto">
                                <div class="card-header  bg-info border-info p-1">
                                    <b-form-input v-model="serchArt"  class="border-info" id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                                     <label class="custom-control-label text-white mt-1 mb-1" >{{ui_type}}</label>
                                </div>
    
                                <b-list-group flush  >
                           
                                <b-list-group-item  v-for="item in filterArticle" :key="item.ref" button class="p-1" variant="info" @click="art_click(item)">
                                     {{ item.ref }}
                                    <p class="m-0 p-0">Dim: <small>{{item.nom_dimension}}</small> </p>
                                    <p class="m-0 p-0">Qté: <small>{{item.total_qte}}</small> </p>
                                    <p class="m-0 p-0">Unité: <small>{{item.unite}}</small> </p>
                                     </b-list-group-item>
    
                              
    
                                </b-list-group>
                                </div>
    
    
    
    
    
    
                    </b-collapse>
    
                    <div class="col h-100 overflow-auto mt-2 mr-4 ml-1">
    
                        <b-collapse v-if="is_valid==0" id="collap-form" visible>
                                    <form @submit.prevent="handleSubmit" >
    
    
                                    <div class="row">
                                    <div class="form-group col">
                                        <label for="ref" class="form-control-sm mb-0">Référence</label>
                                        <input type="text" v-model="itemForm.ref" id="ref" name="ref" class="form-control form-control-sm"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.ref.$error }" />
                                        <div v-if="isSubmitted && !$v.itemForm.ref.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                    </div>
    
    
                                    <div class="form-group col">
                                        <label for="dim" class="form-control-sm mb-0">Dimentions</label>
                                        <input type="text" v-model="itemForm.dim" id="dim" name="dim" class="form-control form-control-sm"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.dim.$error }" />
                                        <div v-if="isSubmitted && !$v.itemForm.dim.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
    
                                    </div>
    
    
    
    
                                    </div>
    
                                    <div class="row"> 
    
                                    <div class="form-group col">
                                        <label for="qte_min" class="form-control-sm mb-0">Quantité : </label>
                                        
                                          <input type="text" v-model="itemForm.qte" id="qte_min" name="qte_min" class="form-control form-control-sm"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.qte.$error }" />
                                        <div v-if="isSubmitted && !$v.itemForm.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                        <div v-if="isSubmitted && !$v.itemForm.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                                    </div>
    
    
                                            <div class="form-group col">
                                                <label for="unite" class="form-control-sm mb-0">Unité :</label>
                                                <input type="text" v-model="itemForm.unite" id="unite" name="unite" class="form-control form-control-sm"/>
                                            </div>
    
    
                                    </div>

    
    
    
    
    
    
                                    
                                    <div class="form-group">
                                    <textarea name="description" id="description"  rows="2"  placeholder="Description ..." class="form-control form-control-sm" v-model="itemForm.description"></textarea>
                                    </div>
    
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-secondary btn-block">Ajouter Article/Produit</button>
                                    </div>
    
     
    
                                    </form>
                        </b-collapse>
    
         
 
    
                          <b-table 
            sort-icon-left
            small 
            responsive 
            :fields="fieldsArticle" 
            :items="ArticlesList"
            >
                <template #cell(actions)="row">
                        <b-button v-if="is_valid==0" pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                    <i class="fa fa-trash-alt"></i>
                            
                        </b-button>
                </template>
            
            </b-table>
    
    
    
    
             
    
    </div>
    
      </div>
    
    
    
        <!-- les trucs public -->
    
    
          <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>
    
     </div>
    
    
    
    
    
    
      
    </template>
    
    <script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {required,decimal} from "vuelidate/lib/validators";
     import jsPDF from 'jspdf';
    import autoTable from 'jspdf-autotable';
    
    export default {
     props: ['idBon','nomBon','nClient','idProjet','nProjet','ddate','valider'],
    
     
        computed: {
                //3t pdf
    get_entete3t() {
      return this.$store.getters.get_entete3t;
    },  
    get_email_3t() {
      return this.$store.getters.get_email_3t;
    },
    get_contact_3t() {
      return this.$store.getters.get_contact_3t;
    },
    get_pied_pag_pdf3t1() {
      return this.$store.getters.get_pied_pag_pdf3t1;
    },
    get_pied_pag_pdf3t2() {
      return this.$store.getters.get_pied_pag_pdf3t2;
    },
    get_pied_pag_pdf3t3() {
      return this.$store.getters.get_pied_pag_pdf3t3;
    }, 
    //fin 3t pdf
                d_get_gst_proj_valider_bn_charg() {
          return this.$store.getters.get_gst_proj_valider_bn_charg;
        },  
    
                 d_logo_w() {
          return this.$store.getters.get_logo_w;
        },
                d_logo_h() {
          return this.$store.getters.get_logo_h;
        },
                d_logo_img() {
          return this.$store.getters.get_logo_img;
        }, 
            d_img_logo_pdf() {
          return this.$store.getters.get_img_logo_pdf;
        }, 
        d_logo_w_pdf() {
          return this.$store.getters.get_logo_w_pdf;
        },
        d_logo_h_pdf() {
          return this.$store.getters.get_logo_h_pdf;
        },
        d_pied_pag_pdf1() {
          return this.$store.getters.get_pied_pag_pdf1;
        },
        d_pied_pag_pdf2() {
          return this.$store.getters.get_pied_pag_pdf2;
        },
        d_pied_pag_pdf3() {
          return this.$store.getters.get_pied_pag_pdf3;
        },

                 d_logo_e() {
          return this.$store.getters.get_logo_e;
        },
                d_logo_p() {
          return this.$store.getters.get_logo_p;
        },
    
            entet_pag() {
    
          if(this.$store.getters.get_entete)
          {
                return this.$store.getters.get_entete;
          }
          else
          {
                return '';
          }
        },
        sousentet_pag() {
    
          if(this.$store.getters.get_sousentete)
          {
                return this.$store.getters.get_sousentete;
          }
          else
          {
                return '';
          }
        },
        pied_pag() {
    
          if(this.$store.getters.get_pied)
          {
                return this.$store.getters.get_pied;
          }
          else
          {
                return '';
          }
        },
        user_name() {
    
          if(this.$store.getters.get_user)
          {
                return this.$store.getters.get_user.name;
          }
          else
          {
                return '';
          }
        },
        filterArticle() {
            return this.listArt.filter(article => {
    
                 return article.ref.toLowerCase().includes(this.serchArt.toLowerCase())
    
          })
        },
        filterCategorie() {
                return this.listCat.filter(category => {
                return category.nom.toLowerCase().includes(this.searchCateg.toLowerCase())   
          })
        },
        

        },
        mounted(){
    
    
            this.is_valid= this.valider
    
                if(this.idBon)
                {
    
    
                this.fetchPhaseList(this.idProjet)
    
    
    
                this.fetchListArts(this.idBon)
                }
                else
                {
                this.flashMessage.show({
                                                status: 'error',
                                                title: 'Erreur de Chargement',
                                                message: 'Impossible charger les données de ce bon'
                })
                }
    
        },
        data () {
            return {
                ui_type:'',
                is_valid:0,
                comptes:[],
                itemVal: {
                        bank_compte_id:'',
                        date: new Date().toJSON().slice(0,10).replace(/-/g,'-')},
    
                fieldsArticle: [
                    {key: 'ref', label: 'Réf'},
                    {key: 'dim', label: 'Dim'},
                    {key: 'qte', label: 'Quantité'},
                    {key: 'unite', label: 'Unité'},
                    {key: 'description', label: 'Description'},
                    { key: 'actions', label: '' }
                ],
    
                searchCateg:'',
                serchArt:'',
                ArticlesList:[],
                listCat: [],
                listArt: [],
                itemForm: {
                        id:'',
                        ref:'',
                        dim:'',
                        description:'',
                        qte:'',
                        unite:''
                        },
                isSubmitted: false,
                isLoading: false
            }
        },
        validations: {
                itemForm: {
                    ref: {
                        required
                    },
                    qte: {
                        required,
                        decimal
                    },
                    dim: {
                        required
                    },
                    unite: {
                        required,
                    },
                }
    
        },
        methods:{
    
            fetchPhaseList: function (proj_id) {
                        this.isLoading=true;
                        this.$http.get('/etapesProjet/'+proj_id)
                        .then((result) => {
                                    this.listCat = result.data.data
                                    this.isLoading=false;
                        })
                        .catch(error=> {
                                    console.log(error)
                                    this.isLoading=false;
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur de Chargement',
                                    message: 'Impossible charger les données.. Actualisez et Réessayez'
                                    })
                        });
            },
            cat_click(id_cat)
            {
                        this.isLoading=true;
                        this.$http.get('/sortisEtapeResum/'+id_cat)
                        .then((result) => {
                                    this.listArt = result.data.data
    
                                    this.ui_type = "Articles De L'Etape"
                                    
                                    this.isLoading=false;
    
    
                                    //effacer le search:
                                    this.serchArt=''
                        })
                        .catch(error=> {
                                    console.log(error)
                                    this.isLoading=false;
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur de Chargement',
                                    message: 'Impossible charger les données.. Actualisez et Réessayez'
                                    })
                        });
    
            },
            art_click(art)
            {
    
    
                this.itemForm.ref=art.ref
                this.itemForm.dim=art.nom_dimension
                this.itemForm.qte=art.total_qte
                this.itemForm.description=''
                this.itemForm.unite = art.unite
    
            },
    
            fetchListArts(id_bn)
            {
                        this.isLoading=true;
                        this.$http.get('/detaiProjetBonCharge/'+id_bn)
                        .then((result) => {
                                    this.ArticlesList = result.data.data
                                    this.isLoading=false;
    
                        })
                        .catch(error=> {
                                    console.log(error)
                                    this.isLoading=false;
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur de Chargement',
                                    message: 'Impossible charger les données.. Actualisez et Réessayez'
                                    })
                        });
    
    
            },
            handleSubmit(){
    
                    this.isSubmitted = true;
    
                    this.$v.$touch();
                    if (this.$v.$invalid) {
                    this.flashMessage.show({
                    status: 'info',
                    title: 'Vérifiez les données et Reésayez !!',
                    })
                    }
                    else 
                    {
    
                                this.isLoading=true;
    
                                this.$http.post('/ProjetBonChargeDetails',{
                                ref:this.itemForm.ref,
                                dim:this.itemForm.dim,
                                description:this.itemForm.description,
                                qte:this.itemForm.qte,
                                unite:this.itemForm.unite,
                         
                                projet_bon_charges_id: this.idBon
    
                                    })
                                    .then(response => {
    
                                        this.isLoading=false;
                                        this.ArticlesList.unshift(response.data.data);
    
                                        //desactiver la validation:
                                        this.isSubmitted=false
                                        //afficher message
                                        this.flashMessage.show({
                                        status: 'success',
                                        title: 'Données sauvegardées',
                                        message: 'Les données sont sauvegardées correctement '
                                    })
    
    
                                    })
                                    .catch(error=> {console.log(error)
                                    this.isLoading=false;
    
    
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur De Sauvegarde',
                                    message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                    })
                                    });
                               
    
                    }
    
            },
            del_click(itemA){
    
                this.isLoading=true;
    
                this.$http.delete('/ProjetBonChargeDetails/'+itemA.id)
                    .then(response => {
                    console.log(response.data)
                    this.isLoading=false;   
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données Supprimées',
                    message: 'Les données sont supprimées correctement'
                    })
                        let index = this.ArticlesList.findIndex(item => item.id === itemA.id)
    
                        this.ArticlesList.splice(index, 1)
                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;
    
    
                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Suppression',
                    message: 'Impossible  de supprimer les données .. Réessayez !!'
                    })
                    });
    
            },
            NewBon()
            {
                            this.$router.push({ 
                            name: 'projetPageDetail', 
                            params: { 
                            idBon: this.idProjet,
                            nClient: this.nClient,
                            } 
                            });
            },
            del_BonClick() {
    
                                    this.$confirm("Voulez-vous vraiment supprimer ce Bon??")
                                    .then(() => {
    
                                        this.BnDell(this.idBon)
                                    })
                                    .catch(error=> {
                                    console.log(error)
    
                                        });
    
    
    
    
            },
            BnDell(id_bn){
    
                this.isLoading=true;
    
                this.$http.delete('/ProjetBonCharges/'+id_bn)
                    .then(response => {
                    console.log(response.data)
                    this.isLoading=false;   
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données Supprimées',
                    message: 'Les données sont supprimées correctement'
                    })
    
                    //goto first page:
                    this.NewBon();
    
    
    
    
                        })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;
    
    
                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Suppression',
                    message: 'Impossible  de supprimer les données .. Réessayez !!'
                    })
                    });
    
            },
            clickValiderBon(typ)
            {
            if(typ==1)
            {
                    
                                            this.$confirm("Voulez-vous vraiment VALIDER ce Bon??")
                                            .then(() => {
    
                                                this.validerBon(this.idBon,1)
                                            })
                                            .catch(error=> {
                                            console.log(error)
    
                                                });
    
            }
            else
            {
                                            this.$confirm("Voulez-vous vraiment DEVALIDER ce Bon??")
                                            .then(() => {
    
                                                this.validerBon(this.idBon,0)
                                            })
                                            .catch(error=> {
                                            console.log(error)
    
                                                });
            }    
            },
            validerBon(id_bn,typ)
            {
    
                this.isLoading=true;
    
                this.$http.get('/validProjetBoncharge/'+id_bn+'/'+typ)
                    .then(response => {
                    console.log(response.data)
    
    
                        this.is_valid=typ;
    
                    this.isLoading=false;   
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Bon valider',
                    message: 'Les données sont validées correctement'
                    })
    
    
    
    
                        })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;
    
    
                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Validation',
                    message: 'Impossible  de valider les données .. Réessayez !!'
                    })
                    });
    
            },
            AfficherTousArts()
            {
                        this.isLoading=true;
                        this.$http.get('/sortisProjetResum/'+this.idProjet)
                        .then((result) => {
                                    this.listArt = result.data.data
    
                                    this.ui_type = "Articles Du Projet"
                                    
                                    this.isLoading=false;
    
    
                                    //effacer le search:
                                    this.serchArt=''
                        })
                        .catch(error=> {
                                    console.log(error)
                                    this.isLoading=false;
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur de Chargement',
                                    message: 'Impossible charger les données.. Actualisez et Réessayez'
                                    })
                        });
    
            },
    
    //printing 
    
            export_pdf_charge()
            {
    
                var imgData=this.d_img_logo_pdf;
    
                var imgData_w=this.d_logo_w_pdf;
                var imgData_h=this.d_logo_h_pdf;
    
    //3t pdf
        var local_entet_pag = this.get_entete3t
        var local_get_email_3t = this.get_email_3t
        var local_get_contact_3t = this.get_contact_3t


        var piedPg_local= this.get_pied_pag_pdf3t1;
        var piedPg_local2= this.get_pied_pag_pdf3t2;
        var piedPg_local3= this.get_pied_pag_pdf3t3
    //fin 3t pdf
    
    
    
                var n_local = this.nomBon;
                var date_local = this.ddate;
    
                var client_local = this.nClient;
                var depot_local = this.nProjet;
    
                const doc = new jsPDF();
    
    

                autoTable(doc, {
            body: [
                [
                {
                    content: '',
                    styles: {
                    halign: 'left'
                    }
                },
                {
                    content: '',
                    styles: {
                    halign: 'right'
                    }
                }
                ],
            ],

                didDrawCell: function (data) {

            if (data.section === 'body' && data.column.dataKey === 0) {


                    doc.addImage(imgData,'JPEG',data.cell.x,data.cell.y,imgData_w,imgData_h);
                    

            }


            if (data.section === 'body' && data.column.dataKey === 1) {
                doc.autoTable({
                startY: data.cell.y + 0.7,
                margin: { left: data.cell.x },
                tableWidth: data.cell.width - 2,
                styles: {
                    maxCellHeight: 5,
                },

                body: [
                    [
                        {
                        content: local_entet_pag,
                        styles: {
                            halign: 'right',
                            fontSize : 11,
                            textColor : '#000000',
                        
                            lineColor:'#89663c', 
                            lineWidth:{top: 0, right: 1.5, bottom: 0, left: 0},
                            cellPadding: {top: 0, right: 3, bottom: 0, left: 0},

                        },
                        
                        },


                    ]
            ],
            theme: 'grid'
                })
            

                                        
            }





            },



            theme: 'plain',
            styles: { minCellHeight: 18 },
            margin: {bottom: 0,left:7 ,right:7 ,top:9},
            columnStyles: {
                0: {
                    cellWidth: 80
                },
                1: {
                    cellWidth: 'auto'
                }}



            });


        //table nom bon 
            autoTable(doc, {
              body: [
                [
                  {
                    styles: {                   
                    lineColor:'#89663c', 
                    minCellHeight:10,
                    lineWidth:{top: 0, right: 1.5, bottom: 0, left: 0},
                    cellPadding: {top: 0, right: 3, bottom: 0, left: 0},

                  },
                  },
                
                ],
              ],

                        didDrawCell: (data) => {
                            if (data.section === 'body' && data.column.dataKey === 0) {
                               doc.setFillColor(22, 54, 92);
                               doc.setDrawColor(22, 54, 92);
                               doc.roundedRect( data.cell.x , data.cell.y, data.cell.width-3, data.cell.height, 0, 3, 'FD');
                                  doc.setFontSize(16)
                                  doc.setTextColor(255,255,255)
                                 doc.text('BON DE CHARGE', data.cell.x + 14, data.cell.y+7)

         

                            }


                        },


cellPadding:{left: 6 },
    margin: {left: 125,right:9 },

    startY: 39,

      theme: 'grid',

    });

        //ligne
            doc.setDrawColor(22,54,92);
          doc.setLineWidth(0.5);
          doc.line(7, 52, 202, 52);



    //client

    autoTable(doc, {
      body: [
        [
          {
            styles: {
              halign: 'right'
            }
          }
        ],
      ],

    didDrawCell: function (data) {
      if (data.section === 'body' && data.column.dataKey === 0) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },

        body: [
              [
                {
                  content: 'Client : ',
                  styles: {
                    halign: 'left',
                    fontStyle:'bold',
                    fontSize : 11,
                    textColor : '#2f64a4',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                  }
                },

                {
                  content: client_local,
                  styles: {
                    halign: 'left',
                    fontSize : 11,
                    textColor : '#000000',
                    lineColor:'#16365c', 
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

              [
                {
                  content: 'Projet : ',
                  styles: {
                    halign: 'left',
                    fontStyle:'bold',
                    fontSize : 11,
                    textColor : '#2f64a4',
                    lineColor:'#16365c', 
                    lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                  }
                },

                {
                  content: depot_local+' ',
                  styles: {
                    halign: 'left',
                    fontSize : 11,
                    textColor : '#000000',
                    lineColor:'#16365c', 
                    lineWidth:{top: 0, right: 0, bottom: 0, left: 0},
                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }





    },



      theme: 'plain',
       styles: { minCellHeight: 30.5 },

        margin: { right: 120 },
        startY: 56,


    });

    //data et num

    autoTable(doc, {
      body: [
        [
          {
            styles: {
              halign: 'right'
            }
          }
        ],
      ],

    didDrawCell: function (data) {
      if (data.section === 'body' && data.column.dataKey === 0) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },

        body: [
              [
                {
                  content: 'Date : ',
                  styles: {
                    halign: 'left',
                    fontStyle:'bold',
                    fontSize : 11,
                    textColor : '#2f64a4',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                  }
                },

                {
                  content: date_local,
                  styles: {
                    halign: 'left',
                    fontSize : 11,
                    textColor : '#000000',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

              [
                {
                  content: 'Bon N° : ',
                  styles: {
                    halign: 'left',
                    fontStyle:'bold',
                    fontSize : 11,
                    textColor : '#2f64a4',
                    lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                  }
                },

                {
                  content: n_local+' ',
                  styles: {
                    halign: 'left',
                    fontSize : 11,
                    textColor : '#000000',
                    lineWidth:{top: 0, right: 0, bottom: 0, left: 0}
                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }





    },



      theme: 'plain',
       styles: { minCellHeight: 30.5 },

        margin: { left: 107 },
        startY: 56,


    });

    
    
    
                let article_body=[]
    
                this.ArticlesList.forEach(function (item) {
    
    
                    article_body.push([item.ref,item.dim, item.description, item.qte.toString(), item.unite]);
                });
    
    
    
    
                
    
        autoTable(doc, {
        head: [['Référence','Dimension', 'Description', 'Quantité', 'Unité']],
      body: article_body,
      styles: { minCellWidth: 30 },
      textColor : '#000000',
      startY:80,
      //margin: {bottom: 40 },
      margin: {bottom: 45,left:8 ,right:8 ,top:8},
      didDrawPage: function (data) {

            // Footer
            //  var str = 'P:' + doc.internal.getNumberOfPages()

          doc.setFontSize(11)

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()




    //foter

autoTable(doc, {
      body: [
        [
          {
            styles: {
              halign: 'right'
            },
           
          },
          {
            styles: {
              halign: 'right'
            },
           
          },
          {
            styles: {
              halign: 'right'
            },
           
          }
        ],
      ],

    didDrawCell: function (data) {
      if (data.section === 'body' && data.column.dataKey === 0) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },



        body: [
              [
                {
                  content: piedPg_local,
                  styles: {
                    halign: 'left',
                    fontSize : 9,
                    textColor : '#000000',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }
      if (data.section === 'body' && data.column.dataKey === 1) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },

        body: [
              [
                {
                  content: piedPg_local2,
                  styles: {
                    halign: 'left',
                    fontSize : 9,
                    textColor : '#000000',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }

      if (data.section === 'body' && data.column.dataKey === 2) {
        doc.autoTable({

          startY: data.cell.y + 2,
          margin: { left: data.cell.x + 2 },
          tableWidth: data.cell.width - 4,
          styles: {
            maxCellHeight: 4,
            minCellWidth: 30
          },

        body: [
              [
                {
                  content: piedPg_local3,
                  styles: {
                    halign: 'left',
                    fontSize : 9,
                    textColor : '#000000',
                  lineWidth:{top: 0, right: 0, bottom: 0, left: 0}

                  }
                }
              ],

      ],

       theme: 'grid',

        })
      
                                 
      }
    },



      theme: 'plain',
       styles: { minCellHeight: 10.5 },
       margin: {bottom: 0,left:8 ,right:8 ,top:0},
      //  margin: { left: 107 },
        startY: pageHeight - 43
        ,


    });


        doc.setTextColor(72,82,220)
          doc.text(local_get_email_3t, data.settings.margin.left+8, pageHeight - 10)
          doc.text(local_get_contact_3t, 170, pageHeight - 10)

          doc.setDrawColor(22,54,92);
          doc.setLineWidth(0.5);
          doc.line(10, pageHeight - 15, 200, pageHeight - 15);




    },

 
      theme: 'striped',
      headStyles:{
        textColor : '#FFFFFF',
        fontSize : 12,
        fillColor: '#16365c'
      },

    });




    
    

    
                doc.save('Bon Charge '+n_local+'_'+client_local+'.pdf');
    
    
    
    
            },
    
            },
            components: {
                        Loading      
            }
    
    
    }
    </script>
    
    <style>
    /*------scroll bar---------------------*/
    
    ::-webkit-scrollbar {
      width: 5px;
      height: 7px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: #9cc7f9;
      border: 0px none #ffffff;
      border-radius: 0px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #9cc7f9;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #9cc7f9;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
      border: 0px none #ffffff;
      border-radius: 60px;
    }
    ::-webkit-scrollbar-track:hover {
      background: transparent;
    }
    ::-webkit-scrollbar-track:active {
      background: transparent;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
    .plafond_color{
      background: #ffb0ca;
    }
    </style>