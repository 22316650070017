<template>
  

<div  class="card rounded-lg shadow border border-white bg-white h-100 mb-0 pb-3 overflow-auto" >
    <div class="card-header bg-transparent">
             
           

<div class="row">


    <div class="col">
        

        <h5 class="ml-2 text-secondary font-weight-light"> <span class="fa fa-file-alt"></span> Projet : <span class="ml-2 mr-2 text-success"> {{nomProjet}} </span>

        
         </h5>



        <h6 class="mt-1 ml-2 text-secondary font-weight-normal">Etape : <span class=" text-info"> {{nomEtape}} </span>  </h6>



        
        <div class="mt-1 ml-2 d-inline text-secondary font-weight-normal">Client : <span class="d-inline text-info"> {{nClient}} </span>  </div>



    </div>
    <div v-if="d_gst_proj_cout_prix" class="col">

<div class=" d-flex justify-content-center">
        <h5 class="ml-2 mt-2 text-secondary font-weight-normal"> Coût Global : <span class="ml-2 mr-2 text-dark"> {{total_cout_global}} DH</span></h5>

</div>


    </div>
    <div class="col">
   

                <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                            <i class="fa fa-home fa-2x"></i>
                </b-button>
                <b-button v-if="d_gst_proj_valider_etap && etatProjet== 0" pill size="sm"  class="m-2 float-right" variant="success" @click="clickValiderBon(1)">
                    <i class="fa fa-check"></i>
                    <span> Términée</span> 
                </b-button>

                <b-button  v-if="d_gst_proj_valider_etap && etatProjet== 1" pill size="sm"  class="m-2 float-right" variant="secondary" @click="clickValiderBon(0)">
                    <i class="fa fa-check"></i>
                    <span> Annuler</span> 
                </b-button>

                <b-button v-if="etatProjet== 0" pill size="sm"  class="m-2 float-right" variant="danger" @click="clickSuppEtape">
                    <i class="fa fa-trash-alt"></i>
                    <span> Supprimer</span> 
                </b-button>





    </div>

</div>


    </div>




    <div class="row card-body h-100 p-0 m-2">



                <div v-if="d_gst_proj_entre" class="col-6   background_entrees  shadow overflow-auto mt-2 mr-0 ml-0 p-1">


                    <div class="col overflow-auto">
                                    <b-button v-if="etatProjet== 0" pill size="sm"  class="m-2  float-right" variant="outline-success"  @click="addEntrer_click">
                                        <i class="fa fa-plus"></i>
                                        <span> Ajouter </span> 
                                    </b-button>


                                    <div class="mt-2 ml-0 text-danger h5">Entrées </div>
                    </div>


                                    <b-table 
                                    sort-icon-left
                                    small 
                                    responsive 

                                    :fields="fieldsEtapEnter" 
                                    :items="listEtapEnter"
                                    >



                            <template #head(Art_ref)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                            <template #head(ref)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                            <template #head(qte)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 

                            <template  #head(prix)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 


                            <template #head(nom_lot)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                            <template #head(description)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                                        <template #cell(actions)="row">
                                                <b-button v-if="etatProjet== 0" pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="del_click(row.item)">
                                                            <i class="fa fa-trash-alt"></i>
                                                    
                                                </b-button>
                                        </template>
                                    
                                    </b-table>

                         <div v-if="d_gst_proj_cout_prix" class=" d-flex justify-content-center pt-1 text-dark">
                                <h5 class="font-weight-normal">Total : {{total_entrees}} DH</h5>
                        </div>
                    
      
                </div>



                <div v-if="d_gst_proj_sortie" class="col background_sorties shadow overflow-auto mt-2 mr-0 ml-4">


                    <div class="col overflow-auto">
                                    <b-button v-if="etatProjet== 0" pill size="sm"  class="m-2  float-right" variant="outline-success"  @click="addSorti_click">
                                        <i class="fa fa-plus"></i>
                                        <span> Ajouter </span> 
                                    </b-button>
                                    <div class="mt-2 ml-0 text-danger h5">Sorties </div>
                    </div>


                                    <b-table 
                                    sort-icon-left
                                    small 
                                    responsive 

                                    :fields="fieldsEtapSorti" 
                                    :items="listEtapSorti"
                                    >



                            <template #head(Art_ref)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                            <template #head(ref)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                            <template #head(nom_dimension)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                            <template #head(qte)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                            <template #head(description)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                                        <template #cell(actions)="row">
                                                <b-button v-if="etatProjet== 0" pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="del_sorie_click(row.item)">
                                                            <i class="fa fa-trash-alt"></i>
                                                    
                                                </b-button>



                                                <b-button  v-if="etatProjet== 0" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="edit_sortie_click(row.item, row.index, $event.target)">
                                                <i class="fa fa-pencil-alt"></i>
                                            
                                            </b-button>

                                        </template>
                                    
                                    </b-table>


                </div>


                <div  v-if="d_gst_proj_frais"  class="col-12 background_frais shadow overflow-auto mt-4 mr-0 ml-0">


                    <div class="col overflow-auto">
                                    <b-button v-if="etatProjet== 0" pill size="sm"  class="m-2  float-right" variant="outline-success"  @click="add_frais_click">
                                        <i class="fa fa-plus"></i>
                                        <span> Ajouter </span> 
                                    </b-button>
                                    <div class="mt-2 ml-0 text-danger h5">Frais </div>
                    </div>

  

                                    <b-table 
                                    sort-icon-left
                                    small 
                                    responsive 

                                    :fields="fieldsFrais" 
                                    :items="itemslistFrais"
                                    >



                            <template #head(nom)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                            <template #head(montant)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                            <template #head(description)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                                        <template #cell(actions)="row">
                                                <b-button v-if="etatProjet== 0" pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="fraisDell(row.item)">
                                                            <i class="fa fa-trash-alt"></i>
                                                    
                                                </b-button>

                                                <b-button  v-if="etatProjet== 0" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="edit_frais_click(row.item, row.index, $event.target)">
                                                <i class="fa fa-pencil-alt"></i>
                                            
                                            </b-button>
                                        </template>
                                    
                                    </b-table>


                        <div class=" d-flex justify-content-center pt-1 text-dark">
                                <h5 class="font-weight-normal ">Total : {{total_frais}} DH</h5>
                        </div>
                </div>

                <div v-if="d_gst_proj_emp" class="col-12 background_emp shadow  overflow-auto mt-4 mr-0 ml-0 mb-4">


                    <div class="col overflow-auto">
                                    <b-button v-if="etatProjet== 0" pill size="sm"  class="m-2  float-right" variant="outline-success"  @click="add_employ_click">
                                        <i class="fa fa-plus"></i>
                                        <span> Ajouter </span> 
                                    </b-button>
                                    <div class="mt-2 ml-0 text-danger h5">Heures De Travail </div>
                    </div>

     
                                    <b-table 
                                    sort-icon-left
                                    small 
                                    responsive 

                                    :fields="fieldsEmploye" 
                                    :items="itemslistEmploye"
                                    >

                            <template #head(code)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                            <template #head(nom_prenom)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                            <template #head(departement)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                            <template #head(prix_heur)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                            <template #head(nbr_heur)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                            <template #head(prix_total)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 

                            <template #head(description)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                                        <template #cell(actions)="row">
                                                <b-button v-if="etatProjet== 0" pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="fraisEmploy(row.item)">
                                                            <i class="fa fa-trash-alt"></i>
                                                    
                                                </b-button>
                                                
                                                <b-button  v-if="etatProjet== 0" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="edit_empoy_click(row.item, row.index, $event.target)">
                                                <i class="fa fa-pencil-alt"></i>
                                            
                                            </b-button>
                                        </template>
                                    
                                    </b-table>          


                        <div class=" d-flex justify-content-center pt-1 text-dark">
                                <h5 class="font-weight-normal ">Total: {{total_emp}} DH </h5>
                        </div>     
     
                </div>


  </div>



    <!-- le modal -->
    <b-modal id="entrerModal" title="Entrée" size="lg"  hide-footer>
                         <div class="mb-2">

                            <select class="form-control form-control-sm" id="depot_id" v-model="itemFormEnter.depot_id">
                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>


                         </div>


<div class="box">


          <div class="topRow card border mr-0 ml-0 mt-0 mb-0 p-0 overflow-auto ">
                    <div class="row m-0">
                                    <div v-for="itemcat in itemslistArticle" :key="itemcat.id"  class="col-xl-3 col-md-4 mb-2 mt-2">
                                      <div class="bg_enter_art h-100 ">
                                          <div class="CardBady  h-100">

                                                  <b-button size="sm" block  class="pt-3 pb-3 text-secondary  h-100" variant="outline-light" @click="Article_click(itemcat)">
                                                      <div class="h6 font-weight-normal text-danger  NonCard mb-1">{{itemcat.nom}} </div>
                                                  </b-button>


                                          </div>
                                      </div>
                                      </div>
                    </div>


            </div>


            <div class="bottomRow card  border mr-0 ml-0 mb-0 mt-3 overflow-auto">
                    <div class="row m-0">
                                    <div v-for="itemcat in itemslistEnter" :key="itemcat.id"  class="col-xl-3 col-md-4 mb-2 mt-2">
                                      <div class="h-100  bg_notif_stock">
                                          <div class="CardBady  h-100 ">

                                                  <b-button size="sm" block  class="pt-2 pb-2 text-secondary  h-100" variant="outline-light" @click="Enter_click(itemcat)">
                                                      <div class="h6 font-weight-normal text-danger  NonCard mb-1">{{itemcat.nom}} </div>
                                                  </b-button>


                                          </div>
                                      </div>
                                      </div>
                    </div>
            </div>
              


</div> <!-- fin box-->

            <form v-if="itemFormEnter.projet_enter_id" @submit.prevent="handleSubmitEnter" >
            <div class="row">
            <div class="form-group col">
                <label for="ref" class="form-control-sm mb-0">Référence</label>
                <input type="text" v-model="itemFormEnter.ref" id="ref" name="ref" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemFormEnter.ref.$error }" />
                <div v-if="isSubmitted && !$v.itemFormEnter.ref.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="form-group col">

                <label for="lot_id" class="form-control-sm mb-0">Lot :</label>


                <select class="form-control form-control-sm" id="lot_id" v-model="itemFormEnter.lot_id"
                :class="{ 'is-invalid': isSubmitted && $v.itemFormEnter.lot_id.$error }">
                <option v-for="item in ListLots" :key="item.id" v-bind:value="item.id">   
                {{ item.nom }} - {{ item.qte }}
                </option>
                </select>
                <div v-if="isSubmitted && !$v.itemFormEnter.lot_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>


            </div>

            </div>

            <div class="row"> 



            <div class="form-group col">
                <label for="qte_min" class="form-control-sm mb-0">Quantité : </label>
                <input type="text" v-model="itemFormEnter.qte" id="qte_min" name="qte_min" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemFormEnter.qte.$error }" />
                <div v-if="isSubmitted && !$v.itemFormEnter.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemFormEnter.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

                                        <div class="form-group col">
                                            <label for="unite" class="form-control-sm mb-0">Unité :</label>
                                            <input type="text" v-model="itemFormEnter.unite" id="unite" name="unite" class="form-control form-control-sm"/>
                                        </div>

            </div>
          
            <div class="form-group">
            <textarea name="description" id="description"  rows="2"  placeholder="Description ..." class="form-control form-control-sm" v-model="itemFormEnter.description"></textarea>
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-secondary btn-block">Ajouter</button>
            </div>

            </form>


    </b-modal>


    <!-- le modal -->
    <b-modal id="sotieModal" title="Sortie" size="lg"  hide-footer>

        <div class="box">


                <div class="topRow card border mr-0 ml-0 mt-0 mb-0 p-0 overflow-auto ">
                            <div class="row m-0">
                                            <div v-for="itemcat in itemslistArticle" :key="itemcat.id"  class="col-xl-3 col-md-4 mb-2 mt-2">
                                            <div class="bg_enter_art h-100 ">
                                                <div class="CardBady  h-100">

                                                        <b-button size="sm" block  class="pt-3 pb-3 text-secondary  h-100" variant="outline-light" @click="Article_sorti_click(itemcat)">
                                                            <div class="h6 font-weight-normal text-danger  NonCard mb-1">{{itemcat.nom}} </div>
                                                        </b-button>


                                                </div>
                                            </div>
                                            </div>
                            </div>


                    </div>


                    <div class="bottomRow card  border mr-0 ml-0 mb-0 mt-3 overflow-auto">
                            <div class="row m-0">
                                            <div v-for="itemcat in itemslistSorti" :key="itemcat.id"  class="col-xl-3 col-md-4 mb-2 mt-2">
                                            <div class="h-100  bg_notif_stock">
                                                <div class="CardBady  h-100 ">

                                                        <b-button size="sm" block  class="pt-2 pb-2 text-secondary  h-100" variant="outline-light" @click="Sorti_click(itemcat)">
                                                            <div class="h6 font-weight-normal text-danger  NonCard mb-1">{{itemcat.nom}} </div>
                                                        </b-button>


                                                </div>
                                            </div>
                                            </div>
                            </div>
                    </div>
                    


        </div> <!-- fin box-->

            <form v-if="itemFormSorti.projet_sorti_id" @submit.prevent="handleSubmitStori" >
            <div class="row">
            <div class="form-group col">
                <label for="ref" class="form-control-sm mb-0">Référence</label>
                <input type="text" v-model="itemFormSorti.ref" id="ref" name="ref" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemFormSorti.ref.$error }" />
                <div v-if="isSubmitted && !$v.itemFormSorti.ref.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>


            </div>


            <div v-if="itemFormSorti.is_fini==1 && itemFormSorti.is_pert==0" class="row"> 

            <div class="form-group col">
                <label for="long" class="form-control-sm mb-0">Longueur : </label>
                <input type="text" v-model="itemFormSorti.long" id="long" name="long" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemFormSorti.long.$error }" />
                <div v-if="isSubmitted && !$v.itemFormSorti.long.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemFormSorti.long.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>


            <div class="form-group col">
                <label for="larg" class="form-control-sm mb-0">Largeur : </label>
                <input type="text" v-model="itemFormSorti.larg" id="larg" name="larg" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemFormSorti.larg.$error }" />
                <div v-if="isSubmitted && !$v.itemFormSorti.larg.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemFormSorti.larg.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

        


            <div class="form-group col">
            <div class="custom-control custom-switch mb-0">
            <input type="checkbox"
             class="custom-control-input" 
             id="is_haut"
                    v-model="itemFormSorti.is_haut"
                    name="is_haut"
                    value=true
                    unchecked-value=false

             >

            <label class="custom-control-label form-control-sm mb-0 pt-0" for="is_haut"> Hauteur : </label>
            </div>



                <input v-if="itemFormSorti.is_haut==true" type="text" v-model="itemFormSorti.haut" id="haut" name="haut" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemFormSorti.haut.$error }" />
                <div v-if="isSubmitted && !$v.itemFormSorti.haut.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemFormSorti.haut.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

            </div>


            <div class="row"> 



            <div class="form-group col">
                <label for="qte_min" class="form-control-sm mb-0">Quantité : </label>
                <input type="text" v-model="itemFormSorti.qte" id="qte_min" name="qte_min" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemFormSorti.qte.$error }" />
                <div v-if="isSubmitted && !$v.itemFormSorti.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemFormSorti.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

             <div class="form-group col">
                <label for="unite" class="form-control-sm mb-0">Unité :</label>
                <input type="text" v-model="itemFormSorti.unite" id="unite" name="unite" class="form-control form-control-sm"/>
             </div>

            </div>
          
            <div class="form-group">
            <textarea name="description" id="description"  rows="2"  placeholder="Description ..." class="form-control form-control-sm" v-model="itemFormSorti.description"></textarea>
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-secondary btn-block">Ajouter</button>
            </div>

            </form>


    </b-modal>



    <!-- le modal -->
    <b-modal id="sotieModalEdit" title="Sortie" size="lg"  hide-footer>


    <form  @submit.prevent="handleSubmitStoriEdit" >
    <div class="row">
    <div class="form-group col">
        <label for="ref" class="form-control-sm mb-0">Référence</label>
        <input type="text" v-model="itemFormSorti.ref" id="ref" name="ref" class="form-control form-control-sm"
            :class="{ 'is-invalid': isSubmitted && $v.itemFormSorti.ref.$error }" />
        <div v-if="isSubmitted && !$v.itemFormSorti.ref.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
    </div>


    </div>


    <div v-if="itemFormSorti.is_fini==1 && itemFormSorti.is_pert==0" class="row"> 

    <div class="form-group col">
        <label for="long" class="form-control-sm mb-0">Longueur : </label>
        <input type="text" v-model="itemFormSorti.long" id="long" name="long" class="form-control form-control-sm"
            :class="{ 'is-invalid': isSubmitted && $v.itemFormSorti.long.$error }" />
        <div v-if="isSubmitted && !$v.itemFormSorti.long.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
        <div v-if="isSubmitted && !$v.itemFormSorti.long.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
    </div>


    <div class="form-group col">
        <label for="larg" class="form-control-sm mb-0">Largeur : </label>
        <input type="text" v-model="itemFormSorti.larg" id="larg" name="larg" class="form-control form-control-sm"
            :class="{ 'is-invalid': isSubmitted && $v.itemFormSorti.larg.$error }" />
        <div v-if="isSubmitted && !$v.itemFormSorti.larg.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
        <div v-if="isSubmitted && !$v.itemFormSorti.larg.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
    </div>




    <div class="form-group col">
    <div class="custom-control custom-switch mb-0">
    <input type="checkbox"
     class="custom-control-input" 
     id="is_haut"
            v-model="itemFormSorti.is_haut"
            name="is_haut"
            value=true
            unchecked-value=false

     >

    <label class="custom-control-label form-control-sm mb-0 pt-0" for="is_haut"> Hauteur : </label>
    </div>



        <input v-if="itemFormSorti.is_haut==true" type="text" v-model="itemFormSorti.haut" id="haut" name="haut" class="form-control form-control-sm"
            :class="{ 'is-invalid': isSubmitted && $v.itemFormSorti.haut.$error }" />
        <div v-if="isSubmitted && !$v.itemFormSorti.haut.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
        <div v-if="isSubmitted && !$v.itemFormSorti.haut.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
    </div>

    </div>


    <div class="row"> 



    <div class="form-group col">
        <label for="qte_min" class="form-control-sm mb-0">Quantité : </label>
        <input type="text" v-model="itemFormSorti.qte" id="qte_min" name="qte_min" class="form-control form-control-sm"
            :class="{ 'is-invalid': isSubmitted && $v.itemFormSorti.qte.$error }" />
        <div v-if="isSubmitted && !$v.itemFormSorti.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
        <div v-if="isSubmitted && !$v.itemFormSorti.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
    </div>

     <div class="form-group col">
        <label for="unite" class="form-control-sm mb-0">Unité :</label>
        <input type="text" v-model="itemFormSorti.unite" id="unite" name="unite" class="form-control form-control-sm"/>
     </div>

    </div>
  
    <div class="form-group">
    <textarea name="description" id="description"  rows="2"  placeholder="Description ..." class="form-control form-control-sm" v-model="itemFormSorti.description"></textarea>
    </div>

    <div class="form-group">
        <button type="submit" class="btn btn-secondary btn-block">Modifier</button>
    </div>

    </form>


</b-modal>



    <b-modal  id="typefraismodal" title="Types"  hide-footer>

                <form @submit.prevent="handleSubmitCatg">

                                <div class="form-group">
                                    <label for="currentType">Type :</label>
                                    <input type="text" v-model="currentType" id="currentType" name="currentType" class="form-control" />

                                </div>





                <div class="form-group">
                    <button type="submit" class="btn  btn-primary btn-block">Ajouter</button>
                </div>

            </form>



            <b-table 
            sort-icon-left
            small 
            sticky-header
            :fields="fieldsTypeFrais" 
            :items="types_frais"
            >
                <template #cell(actions)="row">
                        <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_categclick(row.item)">
                                    <i class="fa fa-trash-alt"></i>
                            
                        </b-button>
                </template>
            
            </b-table>


    </b-modal>



    <!-- le modal -->
    <b-modal id="fraisModal" title="Frais" size="lg"  hide-footer>
        <form @submit.prevent="handleSubmitFrais">
                <div class="row">


                <div class="form-group col">
                <label for="projet_frais_type_id">Type de Frais :</label>


                <b-button @click="categories_Click"  size="sm"  class="ml-1 mr-1 mt-0 float-left pt-0 text-decoration-none" variant="link">
                                    <i class="fa fa-plus"></i>                
                </b-button> 


                                            <select class="form-control" id="projet_frais_type_id" v-model="itemFormFrais.projet_frais_type_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemFormFrais.projet_frais_type_id.$error }">
                                            <option v-for="item in types_frais" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>
                                            </select>

                                <div v-if="isSubmitted && !$v.itemFormFrais.projet_frais_type_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                            </div>


                            <div class="form-group col">
                                <label for="montant">Montant :</label>
                                <input type="text" v-model="itemFormFrais.montant" id="montant" name="montant" class="form-control"
                                    :class="{ 'is-invalid': isSubmitted && $v.itemFormFrais.montant.$error }" />
                                <div v-if="isSubmitted && !$v.itemFormFrais.montant.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                <div v-if="isSubmitted && !$v.itemFormFrais.montant.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                            </div>
                    </div>



            <div class="form-group">
            <label for="Description">Description</label>
            <textarea name="description" id="description"  rows="2" class="form-control" v-model="itemFormFrais.description"></textarea>

            </div>



            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>




        </form>
    </b-modal>

    <b-modal id="EmployeModal" title="Heures De Travail" size="lg"  hide-footer>

    <div class="row h-100 m-2">
  
        <div class="col-5">

               <div class="card border-info h-100 overflow-auto">


        <form @submit.prevent="SubmitFtchClient()" class="bg-info">

                <div class="input-group  border border-info rounded bg-info p-1 col">
                                              <div class="input-group-prepend">
                                                <span class="input-group-text">Départ.. </span>
                                              </div>
                                            
                                            <select  class="form-control" id="rh_departement_id" v-model="itemEmploForm.rh_departement_id">
                                            <option v-for="item in departements" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                            </select>

                </div>

                <div class="border border-info rounded col p-1">
                        <button class="btn m-0 p-1 BgSearch btn-light btn-block border border-info rounded" type="submit" id="button-addon1">   
                          <span class="fa fa-search"></span> Liste
                        </button>
                </div>
                                                 

                                   
        </form>



                            <div class="card-header  bg-info border-info p-1">
                                <b-form-input v-model="serchclient"  class="border-info " id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                            
                                        <div class="custom-control custom-switch form-control-sm ml-1">
                                        <input type="checkbox"
                                        class="custom-control-input" 
                                        id="isCINBarreSearch"
                                                v-model="isCINBarreSearch"
                                                name="isCINBarreSearch"
                                                value=true
                                                unchecked-value=false>

                                        <label class="custom-control-label text-white  mb-0" for="isCINBarreSearch">Recherche Par Code</label>
                                        </div>
                            </div>

                            <b-list-group flush  >
                         
                            <b-list-group-item  v-for="item in filterEmployes" :key="item.id" button class="p-3" variant="info" @click="employe_click(item)">
                                 {{ item.nom_prenom }} -  {{ item.code }} 

                            </b-list-group-item>                          

                            </b-list-group>
               </div>
        </div> 


            <!-- LA PARTIE DES CARDES -->
        <div class="col-7  mr-auto">

            <div v-if="itemEmploForm.rh_departement_id" class="card col">

                    <div class=" d-flex justify-content-center p-2 text-danger">
                        <h5 class="font-weight-normal ">Département / Equipe</h5>
                    </div>


                <form  @submit.prevent="handleSubmitEmployeDep">
                        <div class="row">

                                    <div class="form-group col">
                                        <label for="nbr_heur">Nombre Des Heures :</label>
                                        <input type="text" v-model="itemEmploFormDep.nbr_heur" id="nbr_heur" name="nbr_heur" class="form-control"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemEmploFormDep.nbr_heur.$error }" />
                                        <div v-if="isSubmitted && !$v.itemEmploFormDep.nbr_heur.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                        <div v-if="isSubmitted && !$v.itemEmploFormDep.nbr_heur.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                                    </div>
                        </div>


                    <div class="form-group">
                    <label for="Description">Description</label>
                    <textarea name="description" id="description"  rows="2" class="form-control" v-model="itemEmploFormDep.description"></textarea>
                    </div>



                    <div class="form-group">
                        <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
                    </div>




                </form>
            </div>


            <div v-if="itemEmploForm.rh_empl_id"  class="card col mt-4">
                        <div class=" d-flex justify-content-center p-2 text-danger">
                            <h5 class="font-weight-normal ">{{itemEmploForm.employe_nom}}</h5>
                        </div>
                    <form @submit.prevent="handleSubmitEmploye">
                            <div class="row">



                                    <div class="form-group col">
                                            <label for="prix_heur">Prix Heure :</label>
                                            <input type="text" v-model="itemEmploForm.prix_heur" id="prix_heur" name="prix_heur" class="form-control"
                                                :class="{ 'is-invalid': isSubmitted && $v.itemEmploForm.prix_heur.$error }" />
                                            <div v-if="isSubmitted && !$v.itemEmploForm.prix_heur.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                            <div v-if="isSubmitted && !$v.itemEmploForm.prix_heur.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                                        </div>


                                        <div class="form-group col">
                                            <label for="nbr_heur">Nombre Des Heures :</label>
                                            <input type="text" v-model="itemEmploForm.nbr_heur" id="nbr_heur" name="nbr_heur" class="form-control"
                                                :class="{ 'is-invalid': isSubmitted && $v.itemEmploForm.nbr_heur.$error }" />
                                            <div v-if="isSubmitted && !$v.itemEmploForm.nbr_heur.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                            <div v-if="isSubmitted && !$v.itemEmploForm.nbr_heur.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                                        </div>
                                </div>
                                <div class="form-group">
                    <label for="Description">Description</label>
                    <textarea name="description" id="description"  rows="2" class="form-control" v-model="itemEmploForm.description"></textarea>

                    </div>

                        <div class="form-group">
                            <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
                        </div>




                    </form>
            </div>    


        </div>   


</div> 






    </b-modal>

    <b-modal id="EmployeModalEdit" title="Heures De Travail" size="lg"  hide-footer>

        <div class="row h-100 m-2">

            <div class=" col">
                            <div class=" d-flex justify-content-center p-2 mb-4 text-danger">
                                <h5 class="font-weight-normal ">{{itemEmploFormEdit.employe_nom}}</h5>
                            </div>
                        <form @submit.prevent="handleSubmitEmployeEdit">
                                <div class="row">



                                        <div class="form-group col">
                                                <label for="prix_heur">Prix Heure :</label>
                                                <input type="text" v-model="itemEmploFormEdit.prix_heur" id="prix_heur" name="prix_heur" class="form-control"
                                                    :class="{ 'is-invalid': isSubmitted && $v.itemEmploFormEdit.prix_heur.$error }" />
                                                <div v-if="isSubmitted && !$v.itemEmploFormEdit.prix_heur.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                                <div v-if="isSubmitted && !$v.itemEmploFormEdit.prix_heur.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                                            </div>


                                            <div class="form-group col">
                                                <label for="nbr_heur">Nombre Des Heures :</label>
                                                <input type="text" v-model="itemEmploFormEdit.nbr_heur" id="nbr_heur" name="nbr_heur" class="form-control"
                                                    :class="{ 'is-invalid': isSubmitted && $v.itemEmploFormEdit.nbr_heur.$error }" />
                                                <div v-if="isSubmitted && !$v.itemEmploFormEdit.nbr_heur.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                                <div v-if="isSubmitted && !$v.itemEmploFormEdit.nbr_heur.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                                            </div>
                                    </div>
                                    <div class="form-group">
                        <label for="Description">Description</label>
                        <textarea name="description" id="description"  rows="2" class="form-control" v-model="itemEmploFormEdit.description"></textarea>

                        </div>

                            <div class="form-group">
                                <button type="submit" class="btn  btn-primary btn-block">Modifier</button>
                            </div>




                        </form>
                </div>    



        </div> 

    </b-modal>

      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>






 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";


export default {
 props: ['idBon','nomProjet','idEtape','nomEtape','idClient','nClient','ddate_debut','ddate_fin','idDep','etat'],

 
    computed: {
    d_gst_proj_valider_etap() {
      return this.$store.getters.get_gst_proj_valider_etap;
    },
    d_gst_proj_entre() {
      return this.$store.getters.get_gst_proj_entre;
    },
    d_gst_proj_frais() {
      return this.$store.getters.get_gst_proj_frais;
    },
    d_gst_proj_sortie() {
      return this.$store.getters.get_gst_proj_sortie;
    },
    d_gst_proj_emp() {
      return this.$store.getters.get_gst_proj_emp;
    },
    d_gst_proj_cout_prix() {
      return this.$store.getters.get_gst_proj_cout_prix;
    },
    filterEmployes() {
       return this.employeList.filter(clnt => {

            if(this.isCINBarreSearch)
            {
                if(clnt.code)
                {
                return clnt.code.toLowerCase().includes(this.serchclient.toLowerCase())
                }

            }
            else
            {

                if(clnt.nom_prenom)
                {
                return clnt.nom_prenom.toLowerCase().includes(this.serchclient.toLowerCase())
                }


            }

      }) 
    },
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
    user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },

      total_entrees: function() {
      return this.listEtapEnter.reduce((a, b) => +a + +(b.qte * b.prix), 0);
    },

      total_frais: function() {
      return this.itemslistFrais.reduce((a, b) => +a + +(b.montant), 0);
    },
      total_emp: function() {
      return this.itemslistEmploye.reduce((a, b) => +a + +(b.prix_total), 0);
    },
      total_cout_global: function() {
      return this.total_entrees + this.total_frais+ this.total_emp;
    }
    },
    mounted(){

    this.etatProjet = this.etat;

   if(this.d_gst_proj_cout_prix)
    {
        this.fieldsEtapEnter= this.fieldsEtapEnterPrix
    }


    this.fetchListEtape_entrees()




    this.fetchList()
    },
    data () {
		return {
            etatProjet:'',

            itemEmploFormDep: {
                        nbr_heur:'',
                        description:'',
            },




            fieldsEmploye: [
                {key: 'code', label: 'Code'},
                {key: 'nom_prenom', label: 'Nom et Prénom'},
                {key: 'departement', label: 'Equipe'},
                {key: 'prix_heur', label: 'Prix/U'},
                {key: 'nbr_heur', label: 'Nombre Heures'},
                {key: 'prix_total', label: 'Prix Total'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],
            itemslistEmploye: [],


            itemEmploForm: {
                        id:'',
                        rh_departement_id:'',
                        code:'',
                        employe_nom:'',
                        departement:'',
                        prix_heur:'',
                        nbr_heur:'',
                        prix_total:'',
                        rh_empl_id:'',
                        description:'',
            },
            
            itemEmploFormEdit: {
                        id:'',
                        employe_nom:'',
                        prix_heur:'',
                        nbr_heur:'',
                        prix_total:'',
                        description:'',
            },
            

 departements:[],
            //employes
  employeList:[],
isCINBarreSearch: false,
  serchclient:'',
            //frais
            fieldsFrais: [
                {key: 'nom', label: 'Type'},
                {key: 'montant', label: 'Montant'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],
            itemslistFrais: [],
            itemFormFrais: {
                    id:'',
                    nom:'',
                    montant:'',
                    description:'',
                    projet_frais_type_id:''},


            fieldsTypeFrais: [
                {key: 'nom', label: 'Type'},
                { key: 'actions', label: '' }
            ],
            types_frais:[],
            currentType:'',

            //fin frais
            ListLots:[],
            itemslistArticle:[],

            itemslistEnter:[],
            itemslistSorti:[],

            itemFormEnter:{
                    id:'',
                    Art_ref:'',
                    ref:'',
                    description:'',
                    qte:'',
                    prix:'',
                    unite:'',
                    nom_lot:'',
                    lot_id:'',
                    article_id:'',
                    projet_enter_id:'',
                    depot_id:''},

            itemFormSorti:{
                    id:'',
                    Art_ref:'',
                    ref:'',
                    description:'',
                    qte:'',
                    unite:'',
                    is_pert:0,
                    is_fini:0,
                    nom_dimension:'--',
                    dimension:0,
                    projet_sorti_id:'',
                    
                    long:1,
                    larg:1,
                    is_haut:false,
                    haut:1},
            

            listEtapSorti: [],
            fieldsEtapSorti: [
                {key: 'Art_ref', label: 'Article'},
                {key: 'ref', label: 'Sortie'},
                {key: 'nom_dimension', label: 'Nom Dimension'},
                {key: 'qte', label: 'Qté'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],


            listEtapEnter: [],
            fieldsEtapEnterPrix: [
                {key: 'Art_ref', label: 'Article'},
                {key: 'ref', label: 'Entrée'},
                {key: 'qte', label: 'Qté'},
                {key: 'prix', label: 'Prix'},
                {key: 'nom_lot', label: 'Lot'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],
            fieldsEtapEnter: [
                {key: 'Art_ref', label: 'Article'},
                {key: 'ref', label: 'Entrée'},
                {key: 'qte', label: 'Qté'},
                {key: 'nom_lot', label: 'Lot'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],
 depots:[],
            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
    },
    validations: {
            itemFormEnter: {
                Art_ref: {
                    required
                },
                ref: {
                    required,
                },
                qte: {
                    required,
                    decimal
                },
                prix: {
                    required,
                    decimal
                },
                unite: {
                    required,
                },
                lot_id: {
                    required,
                },
                article_id: {
                    required,
                },
                projet_enter_id: {
                    required,
                }
            },
            itemFormSorti: {
                Art_ref: {
                    required
                },
                ref: {
                    required,
                },
                qte: {
                    required,
                    decimal
                },
                unite: {
                    required,
                },
                is_pert: {
                    required,
                },
                is_fini: {
                    required,
                },
                projet_sorti_id: {
                    required,
                },
                dimension: {
                    required,
                },
                long: {
                    required,
                    decimal
                },
                larg: {
                    required,
                    decimal
                },
                haut: {
                    required,
                    decimal
                },
            },

            itemFormFrais: {
                montant: {
                    required,
                    decimal
                },
                projet_frais_type_id: {
                    required,
                },
            },
            
            itemEmploForm: {
                rh_departement_id: {
                    required
                },
                code: {
                    required,
                },
                employe_nom: {
                    required
                },
                departement: {
                    required,
                },
                prix_heur: {
                    required,
                    decimal
                },
                nbr_heur: {
                    required,
                    decimal
                },
                rh_empl_id: {
                    required,
                },
            },
            itemEmploFormEdit: {
                prix_heur: {
                    required,
                    decimal
                },
                nbr_heur: {
                    required,
                    decimal
                }
            },
            itemEmploFormDep: {
                nbr_heur: {
                    required,
                    decimal
                }
            },

    },
    methods:{
        clickValiderBon(typ)
        {
        
                                this.$confirm("Voulez-vous vraiment modifier l'etat de cet Etape ??")
                                .then(() => {

                                    this.validerBon(this.idEtape,typ)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });

        },
        validerBon(id_bn,ty)
        {

            this.isLoading=true;

            this.$http.get('/modifEtapProjEtat/'+id_bn+'/'+ty)
                .then(response => {


                this.etatProjet = response.data.data.etat

                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Bon valider',
                message: 'Les données sont validées correctement'
                })



                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Validation',
                message: 'Impossible  de valider les données .. Réessayez !!'
                })
                });

        },

        //frais

        edit_empoy_click(item, index, button) {

        this.$confirm("Voulez-vous vraiment modifier cet élément ??")
        .then(() => {


            this.itemEmploFormEdit.id=item.id

            this.itemEmploFormEdit.employe_nom= item.nom_prenom

            this.itemEmploFormEdit.prix_heur=item.prix_heur
            this.itemEmploFormEdit.nbr_heur=item.nbr_heur
            this.itemEmploFormEdit.prix_total=item.prix_total
            
            this.itemEmploFormEdit.description=item.description




        this.$root.$emit('bv::show::modal', 'EmployeModalEdit', button)
        })
        .catch(error=> {
        console.log(error)

        });


        },

        fraisEmploy(itm){


                        this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                        .then(() => {

            
            this.isLoading=true;

            this.$http.delete('/projetEmpl/'+itm.id)
                .then(response => {
                console.log(response.data)
                    this.isLoading=false;

                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslistEmploye.findIndex(item => item.id === itm.id)
                    this.itemslistEmploye.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });


                        })
                        .catch(error=> {
                        console.log(error)
                        });


    
        },

        handleSubmitEmployeDep() {

            
                                //set nom lot
                if(this.itemEmploForm.rh_departement_id)
                {
                let am = this.departements.findIndex(item => item.id === this.itemEmploForm.rh_departement_id)
                this.itemEmploForm.departement= this.departements[am].nom
                }



                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.itemEmploFormDep.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                    this.isLoading=true;
                    this.$http.post('/storeDepartementEmp',{
                    departement:this.itemEmploForm.departement,
                    departement_id:this.itemEmploForm.rh_departement_id,
                    nbr_heur:this.itemEmploFormDep.nbr_heur,
                    description:this.itemEmploFormDep.description,  
                    user:this.user_name,
                    projet_etape_id:this.idEtape


                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslistEmploye=response.data.data
                    this.$bvModal.hide('EmployeModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

                }

        },

        handleSubmitEmploye() {

            
                                //set nom lot
                if(this.itemEmploForm.rh_departement_id)
                {
                let am = this.departements.findIndex(item => item.id === this.itemEmploForm.rh_departement_id)
                this.itemEmploForm.departement= this.departements[am].nom
                }



                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.itemEmploForm.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                                this.employstore()

                }

        },
        employstore(){


            this.isLoading=true;
            this.$http.post('/projetEmpl',{
            code:this.itemEmploForm.code,
            nom_prenom:this.itemEmploForm.employe_nom,
            departement:this.itemEmploForm.departement,

            prix_heur:this.itemEmploForm.prix_heur,
            nbr_heur:this.itemEmploForm.nbr_heur,
            prix_total:(this.itemEmploForm.nbr_heur * this.itemEmploForm.prix_heur),
            description:this.itemEmploForm.description,
            user:this.user_name,
            rh_empl_id:this.itemEmploForm.rh_empl_id,
            projet_etape_id:this.idEtape

                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslistEmploye.unshift(response.data.data);
                    this.$bvModal.hide('EmployeModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },


        handleSubmitEmployeEdit() {

                        

            this.isSubmitted = true;

            this.$v.$touch();
            if (this.$v.itemEmploFormEdit.$invalid) {
            this.flashMessage.show({
            status: 'info',
            title: 'Vérifiez les données et Reésayez !!',
            })
            }
            else 
            {

            this.employedit()

            }

            },

        employedit(){

        this.isLoading=true;

        this.$http.patch('/projetEmpl/'+this.itemEmploFormEdit.id,{
            prix_heur:this.itemEmploFormEdit.prix_heur,
            nbr_heur:this.itemEmploFormEdit.nbr_heur,
            prix_total:(this.itemEmploFormEdit.nbr_heur * this.itemEmploFormEdit.prix_heur),
            description:this.itemEmploFormEdit.description,
            user:this.user_name
            })
            .then(response => {

                this.isLoading=false;

                this.$bvModal.hide('EmployeModalEdit')

                let index = this.itemslistEmploye.findIndex(item => item.id === this.itemEmploFormEdit.id)
                this.itemslistEmploye.splice(index, 1,response.data.data)
                
                this.flashMessage.show({
                status: 'success',
                title: 'Données modifiées',
                message: 'Les données sont modifiées correctement'
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Modification',
                message: 'Impossible  de modifier les données .. Réessayez !!'
            })
            });

        },

        employe_click(emp)
        {
            
                this.itemEmploForm.rh_empl_id=emp.id
                this.itemEmploForm.code=emp.code
                this.itemEmploForm.prix_heur=emp.prix_heure
                this.itemEmploForm.nbr_heur=''

                this.itemEmploForm.employe_nom=emp.nom_prenom
        },
        add_employ_click()
        {
                this.itemEmploFormDep.nbr_heur=''
                this.itemEmploFormDep.description=''

                

                this.itemEmploForm.rh_empl_id=''
                this.itemEmploForm.code=''
                this.itemEmploForm.prix_heur=''
                this.itemEmploForm.nbr_heur=''
                this.itemEmploForm.description=''

                this.itemEmploForm.employe_nom=''

                this.itemEmploForm.rh_departement_id=''

                this.employeList=[]


                 this.isSubmitted= false,

             this.$root.$emit('bv::show::modal', 'EmployeModal' )
        },

        SubmitFtchClient(){

                if (this.itemEmploForm.rh_departement_id) {
                this.fetchListEmployes(this.itemEmploForm.rh_departement_id)
                }
                else 
                {

                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })


                }
        },

        fetchListEmployes(id_depart) {
                    this.isLoading=true;
                    this.$http.get('/empoyeListMin/'+id_depart)
                    .then((result) => {
                                this.employeList = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
//frais


        handleSubmitFrais() {

            
                                //set nom lot
                if(this.itemFormFrais.projet_frais_type_id)
                {
                let am = this.types_frais.findIndex(item => item.id === this.itemFormFrais.projet_frais_type_id)
                this.itemFormFrais.nom= this.types_frais[am].nom
                }


                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.itemFormFrais.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.fraisEdit()
                            }
                            else
                            {

                                this.fraisstore()
                            }   

                }

        },
        fraisstore(){

                        this.isLoading=true;

            this.$http.post('/projetFrais',{
            nom:this.itemFormFrais.nom,
            montant:this.itemFormFrais.montant,
            description:this.itemFormFrais.description,
            
            user:this.user_name,
            projet_frais_type_id:this.itemFormFrais.projet_frais_type_id,
            projet_etape_id:this.idEtape

                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslistFrais.unshift(response.data.data);
                    this.$bvModal.hide('fraisModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        fraisEdit(){

            this.isLoading=true;

            this.$http.patch('/projetFrais/'+this.itemFormFrais.id,{

            nom:this.itemFormFrais.nom,
            montant:this.itemFormFrais.montant,
            description:this.itemFormFrais.description, 
            user:this.user_name,
            projet_frais_type_id:this.itemFormFrais.projet_frais_type_id,
            projet_etape_id:this.idEtape

                })
                .then(response => {

                    this.isLoading=false;
                    this.$bvModal.hide('fraisModal')

                    let index = this.itemslistFrais.findIndex(item => item.id === this.itemslistFrais.id)
                    this.itemslistFrais.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        fraisDell(itm){


            this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
            .then(() => {

            
            this.isLoading=true;

            this.$http.delete('/projetFrais/'+itm.id)
                .then(response => {
                console.log(response.data)
                    this.isLoading=false;

                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslistFrais.findIndex(item => item.id === itm.id)
                    this.itemslistFrais.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });


                        })
                        .catch(error=> {
                        console.log(error)
                        });


    
        },
        add_frais_click(button) {

        
            this.itemFormFrais.nom=''
            this.itemFormFrais.montant=''
            this.itemFormFrais.description=''
            this.itemFormFrais.projet_frais_type_id=''


        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'fraisModal', button)
        },
        edit_frais_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {


                    this.itemFormFrais.id=item.id
                    this.itemFormFrais.nom=item.nom
                    this.itemFormFrais.montant=item.montant

                    this.itemFormFrais.description=item.description
                    
                    this.itemFormFrais.projet_frais_type_id=item.projet_frais_type_id
            

                this.ModalEdit=true;
                this.ModalType='Modifier'

                this.$root.$emit('bv::show::modal', 'fraisModal', button)
                })
                .catch(error=> {
                console.log(error)

                });


        },
//type frais
        categories_Click()
        {

        this.currentType=''
        this.$root.$emit('bv::show::modal', 'typefraismodal')

        },
        handleSubmitCatg() {

            if(this.currentType)
            {
                this.itemslisttoreType(this.currentType)

            }
            else
            {
                this.flashMessage.show({
                status: 'info',
                title: 'Entrez un type de dépense !!',
                })

            }
        },
        itemslisttoreType(nm){
                        this.isLoading=true;

            this.$http.post('/projetFraisType',{
            nom:nm

                })
                .then(response => {

                    this.isLoading=false;
                    this.types_frais.unshift(response.data.data);

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },

        del_categclick(itm) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ?? Attention si cet élément est déjà utilisé ,vous NE pouvez PAS le supprimer")
                 .then(() => {


            this.isLoading=true;

            this.$http.delete('/projetFraisType/'+itm.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                let index = this.types_frais.findIndex(item => item.id === itm.id)
                this.types_frais.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });
                                })

                  //erreur de dialog              
                .catch(error=> {
                console.log(error)

                });




        },

        //sotris
        edit_sortie_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                .then(() => {


                    this.itemFormSorti.id=item.id
                    this.itemFormSorti.Art_ref=item.Art_ref
                    this.itemFormSorti.ref=item.ref
                    this.itemFormSorti.description=item.description 
                    this.itemFormSorti.qte=item.qte
                    this.itemFormSorti.unite=item.unite
                    this.itemFormSorti.is_pert=item.is_pert
                    this.itemFormSorti.is_fini=item.is_fini
                    this.itemFormSorti.nom_dimension=item.nom_dimension
                    this.itemFormSorti.dimension=item.dimension
                    this.itemFormSorti.projet_sorti_id=item.projet_sorti_id
                    
                    this.itemFormSorti.long=1
                    this.itemFormSorti.larg=1
                    this.itemFormSorti.is_haut=false
                    this.itemFormSorti.haut=1


                this.$root.$emit('bv::show::modal', 'sotieModalEdit', button)
                })
                .catch(error=> {
                console.log(error)

                });


                },
            handleSubmitStoriEdit()
            {
                //set nom lot
                if(this.itemFormSorti.is_fini==1 && this.itemFormSorti.is_pert==0)
                {


                            if( this.itemFormSorti.is_haut==false)
                            {
                            this.itemFormSorti.haut=1   
                            this.itemFormSorti.dimension=(this.itemFormSorti.long * this.itemFormSorti.larg);
                            this.itemFormSorti.nom_dimension= this.itemFormSorti.long +" x "+ this.itemFormSorti.larg
                            }
                            else
                            {
                            this.itemFormSorti.dimension=(this.itemFormSorti.long * this.itemFormSorti.larg * this.itemFormSorti.haut);
                            this.itemFormSorti.nom_dimension= this.itemFormSorti.long +" x "+ this.itemFormSorti.larg+" x "+ this.itemFormSorti.haut
                            }



                }
                else
                {
                    this.itemFormSorti.dimension=0;
                    this.itemFormSorti.nom_dimension= "--"
                }


                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.itemFormSorti.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                            this.isLoading=true;
                            
                            this.$http.patch('/projetEtapeSorti/'+this.itemFormSorti.id,{
                            Art_ref:this.itemFormSorti.Art_ref,
                            ref:this.itemFormSorti.ref,
                            description:this.itemFormSorti.description,
                            qte:this.itemFormSorti.qte,
                            unite:this.itemFormSorti.unite,

                            is_pert:this.itemFormSorti.is_pert,
                            is_fini:this.itemFormSorti.is_fini,

                            nom_dimension:this.itemFormSorti.nom_dimension,
                            dimension:this.itemFormSorti.dimension,

                            projet_sorti_id:this.itemFormSorti.projet_sorti_id,
                            user: this.user_name,
                            projet_etape_id: this.idEtape

                                })
                                .then(response => {

                                    this.isLoading=false;
                            
                                    let index = this.listEtapSorti.findIndex(item => item.id === this.itemFormSorti.id)
                                    this.listEtapSorti.splice(index, 1,response.data.data)


                                    this.$bvModal.hide('sotieModalEdit')
                                    

                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })


                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });
                           

                }
                
            },
            del_sorie_click(item) {

                        this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                        .then(() => {


                    this.isLoading=true;

                    this.$http.delete('/projetEtapeSorti/'+item.id)
                        .then(response => {
                        console.log(response.data)
                        this.isLoading=false;   
                        this.flashMessage.show({
                        status: 'success',
                        title: 'Données Supprimées',
                        message: 'Les données sont supprimées correctement'
                        })
                            let index = this.listEtapSorti.findIndex(item => item.id === item.id)
                            this.listEtapSorti.splice(index, 1)

                            })
                        .catch(error=> {console.log(error)
                        this.isLoading=false;


                        this.flashMessage.show({
                        status: 'error',
                        title: 'Erreur De Suppression',
                        message: 'Impossible  de supprimer les données .. Réessayez !!'
                        })
                        });


                                        })
                                        .catch(error=> {
                                        console.log(error)

                                            });




            },
            handleSubmitStori()
            {
                //set nom lot
                if(this.itemFormSorti.is_fini==1 && this.itemFormSorti.is_pert==0)
                {


                            if( this.itemFormSorti.is_haut==false)
                            {
                            this.itemFormSorti.haut=1   
                            this.itemFormSorti.dimension=(this.itemFormSorti.long * this.itemFormSorti.larg);
                            this.itemFormSorti.nom_dimension= this.itemFormSorti.long +" x "+ this.itemFormSorti.larg
                            }
                            else
                            {
                            this.itemFormSorti.dimension=(this.itemFormSorti.long * this.itemFormSorti.larg * this.itemFormSorti.haut);
                            this.itemFormSorti.nom_dimension= this.itemFormSorti.long +" x "+ this.itemFormSorti.larg+" x "+ this.itemFormSorti.haut
                            }



                }
                else
                {
                    this.itemFormSorti.dimension=0;
                    this.itemFormSorti.nom_dimension= "--"
                }


                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.itemFormSorti.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {
                            this.isLoading=true;

                            this.$http.post('/projetEtapeSorti',{
                            Art_ref:this.itemFormSorti.Art_ref,
                            ref:this.itemFormSorti.ref,
                            description:this.itemFormSorti.description,
                            qte:this.itemFormSorti.qte,
                            unite:this.itemFormSorti.unite,

                            is_pert:this.itemFormSorti.is_pert,
                            is_fini:this.itemFormSorti.is_fini,

                            nom_dimension:this.itemFormSorti.nom_dimension,
                            dimension:this.itemFormSorti.dimension,

                            projet_sorti_id:this.itemFormSorti.projet_sorti_id,
                            user: this.user_name,
                            projet_etape_id: this.idEtape

                                })
                                .then(response => {

                                    this.isLoading=false;
                                    this.listEtapSorti.unshift(response.data.data);


                                    this.$bvModal.hide('sotieModal')
                                    

                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })


                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });
                           

                }
                
            },
            addSorti_click() {

                //list
                this.itemslistSorti=[]

                this.itemFormSorti.projet_sorti_id=''
                this.itemFormSorti.ref=''
                this.itemFormSorti.description=''


                this.itemFormSorti.unite=''


               this.itemFormSorti.is_pert=0
                this.itemFormSorti.is_fini=0

                this.itemFormSorti.qte=1
                this.itemFormSorti.dimension=0
                this.itemFormSorti.nom_dimension='--'

                this.itemFormSorti.long=1
                this.itemFormSorti.larg=1
                this.itemFormSorti.haut=1
                this.itemFormSorti.is_haut=false
   



                                this.ModalEdit=false;
                                this.ModalType='Ajouter'

                                this.$root.$emit('bv::show::modal', 'sotieModal' )
            },

            Sorti_click(item)
            {

                this.itemFormSorti.projet_sorti_id=item.id
                this.itemFormSorti.ref=item.nom
                this.itemFormSorti.description=item.description,


                this.itemFormSorti.unite=item.unite

                this.itemFormSorti.is_pert=item.is_pert
                this.itemFormSorti.is_fini=item.is_fini

                this.itemFormSorti.qte=1
                this.itemFormSorti.dimension=0
                this.itemFormSorti.nom_dimension='--'
                 
            },

            Article_sorti_click(itm)
            {
                    this.itemFormSorti.Art_ref=itm.nom
                    //charcher les données

                                this.isLoading=true;
                                this.$http.get('/sortisProjetArticle/'+itm.id)
                                .then((result) => {
                                            this.itemslistSorti = result.data.data

                                            this.isLoading=false;
                                })
                                .catch(error=> {
                                            console.log(error)
                                            this.isLoading=false;
                                            this.flashMessage.show({
                                            status: 'error',
                                            title: 'Erreur de Chargement',
                                            message: 'Impossible charger les données.. Actualisez et Réessayez'
                                            })
                                });
            },

        //les entrees 
            del_click(itm) {

                        this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                        .then(() => {


                    this.isLoading=true;

                    this.$http.delete('/projetEtapeEnters/'+itm.id)
                        .then(response => {
                        console.log(response.data)
                        this.isLoading=false;   
                        this.flashMessage.show({
                        status: 'success',
                        title: 'Données Supprimées',
                        message: 'Les données sont supprimées correctement'
                        })


                            let index = this.listEtapEnter.findIndex(item => item.id === itm.id)
                            this.listEtapEnter.splice(index, 1)




                            })
                        .catch(error=> {console.log(error)
                        this.isLoading=false;


                        this.flashMessage.show({
                        status: 'error',
                        title: 'Erreur De Suppression',
                        message: 'Impossible  de supprimer les données .. Réessayez !!'
                        })
                        });


                                        })
                                        .catch(error=> {
                                        console.log(error)

                                            });




            },
            fetchListEtape_entrees()
            {
                        this.isLoading=true;
                        this.$http.get('/getInitInfosEtape/'+this.idEtape)
                        .then((result) => {
                                    this.listEtapEnter = result.data.entre_data
                                     this.listEtapSorti = result.data.sortie_data
                                      this.itemslistFrais = result.data.frais_data
                                       this.itemslistEmploye = result.data.emp_data


                                    this.isLoading=false;

                        })
                        .catch(error=> {
                                    console.log(error)
                                    this.isLoading=false;
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur de Chargement',
                                    message: 'Impossible charger les données.. Actualisez et Réessayez'
                                    })
                        });



            },

            handleSubmitEnter()
            {
                //set nom lot
                if(this.itemFormEnter.lot_id)
                {
                let am = this.ListLots.findIndex(item => item.id === this.itemFormEnter.lot_id)
                this.itemFormEnter.nom_lot= this.ListLots[am].nom
                this.itemFormEnter.prix= this.ListLots[am].prix_achat
                }



                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.itemFormEnter.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {
                            this.isLoading=true;

                            this.$http.post('/projetEtapeEnters',{
                            Art_ref:this.itemFormEnter.Art_ref,
                            ref:this.itemFormEnter.ref,
                            description:this.itemFormEnter.description,
                            qte:this.itemFormEnter.qte,
                            prix:this.itemFormEnter.prix,
                            unite:this.itemFormEnter.unite,
                            nom_lot:this.itemFormEnter.nom_lot,
                            lot_id:this.itemFormEnter.lot_id,
                            article_id:this.itemFormEnter.article_id,
                            projet_enter_id:this.itemFormEnter.projet_enter_id,
                            user: this.user_name,
                            projet_etape_id: this.idEtape

                                })
                                .then(response => {

                                    this.isLoading=false;
                                    this.listEtapEnter.unshift(response.data.data);


                                    this.$bvModal.hide('entrerModal')
                                    

                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })


                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });
                           

                }
                
            },

            Enter_click(item)
            {

                if(this.itemFormEnter.depot_id)
                {
                this.fetchLots(item.article_id,this.itemFormEnter.depot_id)
                }
                else
                {
                                this.flashMessage.show({
                                status: 'info',
                                title: 'Séléctionnez un dépôt !!',
                                })
                }
                this.itemFormEnter.projet_enter_id=item.id
                this.itemFormEnter.ref=item.nom
                this.itemFormEnter.description=item.description,


                this.itemFormEnter.unite=item.unite


               
                this.itemFormEnter.article_id=item.article_id

                this.itemFormEnter.qte=1


                           
            },

            fetchLots(id_art,dep) {
                    this.isLoading=true;
                    this.$http.get('/lotsArtDep/'+id_art+'/'+dep)
                    .then((result) => {
                                this.ListLots = result.data.data
                                this.isLoading=false;


                                if(this.ListLots.length>0)
                                {
                                        this.itemFormEnter.lot_id=this.ListLots[0].id
                                }
                                else
                                {
                                        this.itemFormEnter.lot_id=''
                                }
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
            },

            Article_click(itm)
            {
                    this.itemFormEnter.Art_ref=itm.nom
                    //charcher les données

                                this.isLoading=true;
                                this.$http.get('/entersProjetArticle/'+itm.id)
                                .then((result) => {
                                            this.itemslistEnter = result.data.data

                                            this.isLoading=false;
                                })
                                .catch(error=> {
                                            console.log(error)
                                            this.isLoading=false;
                                            this.flashMessage.show({
                                            status: 'error',
                                            title: 'Erreur de Chargement',
                                            message: 'Impossible charger les données.. Actualisez et Réessayez'
                                            })
                                });
            },

            fetchList: function () {
                        this.isLoading=true;
                        this.$http.get('/projetArticles')
                        .then((result) => {
                                    this.itemslistArticle = result.data.data
                                    this.isLoading=false;

                        })
                        .catch(error=> {
                                    console.log(error)
                                    this.isLoading=false;
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur de Chargement',
                                    message: 'Impossible charger les données.. Actualisez et Réessayez'
                                    })
                        });


            //Types :
                    this.isLoading=true;
                    this.$http.get('/projetFraisType')
                    .then((result) => {
                                this.types_frais = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });     
                                //chager les departements:
                    this.isLoading=true;
                    this.$http.get('/departements')
                    .then((result) => {
                                this.departements = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/depotPerson/'+this.user_dep)
                    .then((result) => {
                                this.depots = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

            },

            addEntrer_click() {

                this.itemFormEnter.depot_id = this.idDep
                //list
                this.itemslistEnter=[]

                this.itemFormEnter.projet_enter_id=''
                this.itemFormEnter.ref=''
                this.itemFormEnter.description=''


                this.itemFormEnter.unite=''

               
                this.itemFormEnter.article_id=''

                this.itemFormEnter.qte=1




                                this.ModalEdit=false;
                                this.ModalType='Ajouter'

                                this.$root.$emit('bv::show::modal', 'entrerModal' )
            },

            NewBon()
            {
                        this.$router.push({ 
                        name: 'projetPageDetail', 
                        params: { 
                        idBon: this.idBon,
                        nClient: this.nClient,
                        } 
                        });
            },

            clickSuppEtape()
            {
            
                                    this.$confirm("Voulez-vous vraiment SUPPRIMER cet etape ??")
                                    .then(() => {

                                        this.supp_etape(this.idEtape)
                                    })
                                    .catch(error=> {
                                    console.log(error)

                                        });

            },
            supp_etape(id_bn)
            {

                this.isLoading=true;

                this.$http.get('/suppEtapesProjAll/'+id_bn)
                    .then(response => {
                    console.log(response.data)
                    this.isLoading=false;   
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Bon valider',
                    message: 'Les données sont validées correctement'
                    })

                    //goto first page:
                        this.$router.push({ 
                            name: 'blClients'

                        });




                        })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Validation',
                    message: 'Impossible  de valider les données .. Réessayez !!'
                    })
                    });

            },





        },
        components: {
                    Loading,
                     
        }


}
</script>

<style>
/*------scroll bar---------------------*/
.background_frais{
/*background: linear-gradient(0deg, rgba(243,227,252,1) 47%, rgba(222,222,252,1) 100%);*/

background: radial-gradient(circle, rgba(243,245,251,1) 54%, rgb(228, 221, 159) 100%);
border-width: 0px;
border-radius:  10px;
}
.background_entrees{
background: radial-gradient(circle, rgba(243,245,251,1) 54%, rgb(178, 211, 255) 100%);
border-width: 0px;
border-radius:  10px;

}
.background_sorties{
background: radial-gradient(circle, rgba(243,245,251,1) 54%, rgba(198,249,251,1) 100%);
border-width: 0px;
border-radius:  10px;

}
.background_emp{
background: radial-gradient(circle, rgba(243,245,251,1) 54%, rgb(245, 198, 251) 100%);
border-width: 0px;
border-radius:  10px;

}
.bg_enter_art{
background: linear-gradient(90deg, rgb(194, 248, 244) 36%, rgb(218, 173, 255) 100%);
border-width: 1px;
border-radius: 10px;
}

.bg_notif_stock{
background: linear-gradient(90deg, rgb(194, 248, 244) 36%, rgb(173, 231, 255) 100%);
border-width: 1px;
border-radius: 10px;
}

.box {
display: flex;
flex-flow: column;
height: 100%;
}
.topRow {

flex: 1 1 auto;
width: 100%;
}
.bottomRow {

flex: 0 1 35%;
width: 100%;
}
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>