<template>
  

<div  class="card rounded-lg shadow border border-white bg-white h-100 mb-0 pb-3 overflow-auto" >
    <div class="card-header bg-transparent">
             
           

<div class="row">


    <div class="col">
        <h5 class="ml-2 text-secondary font-weight-light"> <span class="fa fa-microchip"></span> Article : {{nomArticle}}<span class="ml-2 text-success"> </span> </h5>
    </div>

                <div class="col-5 p-0">

                <b-button pill   class="ml-0 mr-3 pt-1 pb-1 pr-2 pl-2 float-right" variant="outline-primary" @click="NewBon">
                            <i class="fa fa-home"></i>
                    <span> Précédent</span> 
                </b-button>


                </div>

                

</div>


    </div>




    <div class="row card-body h-100 p-0 m-0">

  

                <div class="col h-100 overflow-auto mt-2 mr-0 ml-0">


                    <div class="col overflow-auto">
                                    <b-button pill size="sm"  class="m-2  float-right" variant="success"  @click="addEntre_click">
                                        <i class="fa fa-plus"></i>
                                        <span> Ajouter </span> 
                                    </b-button>
                                    <div class="mt-2 ml-0 text-danger h5">Les Entrées (Matières Premières) </div>
                    </div>

                    
                            <div class="card border border-light shadow">





                                    <b-table 
                                    sort-icon-left
                                    small 
                                    responsive 

                                    :fields="fieldsEntres" 
                                    :items="listEntresProc"
                                    >

                                            <template #head(nom)="data">
                                                <span class="text-info font-weight-light">{{ data.label }}</span>
                                            </template> 
                                                        <template #head(unite)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                                                        <template #head(description)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                                        <template #cell(actions)="row">
                                                <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="DellEntres_click(row.item)">
                                                            <i class="fa fa-trash-alt"></i>
                                                    
                                                </b-button>


                                        </template>
                                    
                                    </b-table>

                            </div>
                </div>


                <div class="col h-100 overflow-auto mt-2 mr-2 ml-0">


                    <div class="col overflow-auto">
                                    <b-button pill size="sm"  class="m-2  float-right" variant="success"  @click="addSorti_click">
                                        <i class="fa fa-plus"></i>
                                        <span> Ajouter </span> 
                                    </b-button>
                                    <div class="mt-2 ml-0 text-danger h5">Les Sorties (Produits)</div>
                    </div>
                            
                <div class=" card border border-light shadow mt-0">


                        <b-table 
                        sort-icon-left
                        small 
                        responsive 

                        :fields="fieldsSorties" 
                        :items="listSortiesProc"
                        >
                            <template #head(nom)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                                                        <template #head(unite)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                                                        <template #head(description)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 

                                                                                    <template #head(is_fini)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 
                                                                                    <template #head(is_pert)="data">
                                <span class="text-info font-weight-light">{{ data.label }}</span>
                            </template> 

                            <template #cell(actions)="rowAr">

                                    <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="DellSorties_click(rowAr.item.id)">
                                                <i class="fa fa-trash-alt"></i>
                                        
                                    </b-button>
                            </template>
                        
                        </b-table> 

                </div>


                       


                </div>

  </div>





        <!-- le modal -->
    <b-modal id="articlesModal" title="Entrée" size="lg" hide-footer>

            <div class="row card-body h-100 overflow-auto p-0 m-0">


                    <div class="col-3 total_heignt overflow-auto mr-3 ml-0 mt-2 p-0">

                                                <div class="card border-success h-100 overflow-auto">
                                                <div class="card-header  bg-success border-success p-1">
                                                                <b-form-input v-model="searchCateg" class="border-info" id="search-cat" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                                                            
                                                                <b-button  @click="AfficherTousArts" size="sm"  class="ml-1 mb-1 mt-1 p-0 text-white text-decoration-none" variant="link">
                                                                <i class="fa fa-list-alt mr-1"></i>  
                                                                Tous Les Articles              
                                                                </b-button> 
                                                </div>

                                                <b-list-group flush >
                                                <b-list-group-item  v-for="item in filterCategorie" :key="item.id" button class="p-1" variant="success" @click="cat_click(item.id)">
                                                        {{ item.nom }} 
                                                        <p> <small>{{item.description}}</small> </p>
                                                </b-list-group-item>

                                                </b-list-group>
                                                </div>




                    </div>


                    <div class="col-3 total_heignt overflow-auto mr-3 ml-0 mt-2 p-0">

                                        <div class="card border-info h-100  overflow-auto">
                                        <div class="card-header  bg-info border-info p-1">
                                            <b-form-input v-model="serchArt"  class="border-info" id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                                        
                                                    <div class="custom-control custom-switch form-control-sm ml-1">
                                                    <input type="checkbox"
                                                    class="custom-control-input" 
                                                    id="isCodeBarreSearch"
                                                            v-model="isCodeBarreSearch"
                                                            name="isCodeBarreSearch"
                                                            value=true
                                                            unchecked-value=false>

                                                    <label class="custom-control-label text-white  mb-0" for="isCodeBarreSearch">Code Barre</label>
                                                    </div>
                                        </div>

                                        <b-list-group flush  >
                                
                                        <b-list-group-item  v-for="item in filterArticle" :key="item.id" button class="p-1" variant="info" @click="art_click(item.id)">
                                            {{ item.ref }}
                                            <p> <small>{{item.description}}</small> </p>
                                            </b-list-group-item>

                                    

                                        </b-list-group>
                                        </div>

                    </div>


                    <div class="col h-100 overflow-auto">
                                            <form @submit.prevent="handleSubmit" class="mt-2">





                                            <div v-if="itemForm.ref" class="card  background_ref">
                                                    <div class="d-flex justify-content-center pt-1  pb-0">
                                                    <h5 class="font-weight-normal">{{itemForm.ref}}</h5>
                                                    </div>
                                            </div>


                                            <div class="row ">

                                                    <div class="form-group col">
                                                        <label for="unite" class="form-control-sm mb-0">Unité :</label>
                                                        <input type="text" v-model="itemForm.unite" id="unite" name="unite" class="form-control form-control-sm"
                                                         :class="{ 'is-invalid': isSubmitted && $v.itemForm.unite.$error }" />
                                                        <div v-if="isSubmitted && !$v.itemForm.unite.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                                    </div>

                                            </div>


                                            <div class="row ">
                                                <div class="form-group col">
                                                <textarea name="description" id="description"  rows="2"  placeholder="Description..." class="form-control form-control-sm" v-model="itemForm.description"></textarea>
                                                </div>
                                            </div>

                                            <div v-if="itemForm.article_id" class="form-group">
                                                <button type="submit" class="btn btn-secondary btn-block">Ajouter Article/Produit</button>
                                            </div>

            

                                            </form>
                    </div>

                
            </div>

    </b-modal>


    <!-- le modal -->
    <b-modal id="sortiModal" title="Sortie/Produit"  hide-footer>
           <form @submit.prevent="handleSubmitsorti">
            <div class="form-group">
                <label for="nom">Nom :</label>
                <input type="text" v-model="itemFormsorti.nom" id="nom" name="nom" class="form-control"
                :class="{ 'is-invalid': isSubmitted && $v.itemFormsorti.nom.$error }" />
                <div v-if="isSubmitted && !$v.itemFormsorti.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>


            <div class="form-group">
                <label for="unite">Unité :</label>
                <input type="text" v-model="itemFormsorti.unite" id="unite" name="unite" class="form-control"
                :class="{ 'is-invalid': isSubmitted && $v.itemFormsorti.unite.$error }" />
                <div v-if="isSubmitted && !$v.itemFormsorti.unite.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>


            <div class="custom-control custom-switch">
            <input type="checkbox"
             class="custom-control-input" 
             id="is_fini"
                    v-model="itemFormsorti.is_fini"
                    name="is_fini"
                    value=true
                    unchecked-value=false

             >
            <label class="custom-control-label" for="is_fini"> Avec Dimensions</label>
            </div>



            <div class="custom-control custom-switch">
            <input type="checkbox"
             class="custom-control-input" 
             id="is_pert"
                    v-model="itemFormsorti.is_pert"
                    name="is_pert"
                    value=true
                    unchecked-value=false

             >
            <label class="custom-control-label" for="is_pert"> Perte</label>
            </div>



            <div class="form-group">
            <label for="Description">Description</label>
            <textarea name="description" id="description"  rows="4" class="form-control" v-model="itemFormsorti.description"></textarea>
            </div>

            <div class="form-group mt-4">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>


        </form>
    </b-modal>



      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
export default {
 props: ['idArticle','nomArticle'],

 
    computed: {
    filterArticle() {
            return this.listArt.filter(article => {

            if(this.isCodeBarreSearch)
            {
                if(article.code_bar)
                {
                return article.code_bar.toLowerCase().includes(this.serchArt.toLowerCase())
                }

            }
            else
            {
                return article.ref.toLowerCase().includes(this.serchArt.toLowerCase()) || article.description.toLowerCase().includes(this.serchArt.toLowerCase())
            }

      })
    },
    filterCategorie() {
            return this.listCat.filter(category => {
            return category.nom.toLowerCase().includes(this.searchCateg.toLowerCase())   
      })
    },
    },
    mounted(){

            this.fetchListProc_entrees()
            this.fetchListProc_sorties()

            this.fetchCategorieList()


    },
    data () {
		return {
            itemFormsorti:{
                    nom:'',
                    unite:'',
                    description:'',
                    projet_enter_id:0,
                    is_pert:false,
                    is_fini:false},

               itemForm: {
                    id:'',
                    ref:'',
                    unite:'',
                    description:'',
                    article_id:''},

            listEntresProc: [],
            listSortiesProc: [],

            fieldsEntres: [
                {key: 'nom', label: 'Entrée'},
                {key: 'unite', label: 'Unité'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],

            fieldsSorties: [
                {key: 'nom', label: 'Sorties'},
                {key: 'unite', label: 'Unité'},
                {key: 'description', label: 'Description'},
                {key: 'is_fini', label: 'Avec Dimension', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }},
                {key: 'is_pert', label: 'Perte', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }},
                { key: 'actions', label: '' }
            ],

            listCat: [],
            listArt: [],

            ModalType:'Ajouter',

            isCodeBarreSearch: false,
            searchCateg:'',

            isSubmitted: false,
            isLoading: false
		}
    },
        validations: {
            itemForm: {
                ref: {
                    required
                },
                article_id: {
                    required
                },
                unite: {
                    required,
                },

            },
            itemFormsorti: {
                nom: {
                    required
                },
                unite: {
                    required,
                },
                is_pert: {
                    required,
                },
                is_fini: {
                    required,
                },

            },
    },
    methods:{



        fetchCategorieList: function () {
                    this.isLoading=true;
                    this.$http.get('/categories')
                    .then((result) => {
                                this.listCat = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        cat_click(id_cat)
        {
                    this.isLoading=true;
                    this.$http.get('/articlesCat/'+id_cat)
                    .then((result) => {
                                this.listArt = result.data.data

                                
                                this.isLoading=false;


                                //effacer le search:
                                this.serchArt=''
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        art_click(id_art)
        {

            let art = this.listArt.findIndex(item => item.id === id_art)
            this.itemForm.ref=this.listArt[art].ref
            this.itemForm.unite = this.listArt[art].unite
            this.itemForm.description=this.listArt[art].description
            this.itemForm.article_id=this.listArt[art].id
            
        },

        handleSubmit(){


                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.itemForm.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                            this.isLoading=true;

                            this.$http.post('/projetEnters',{
                            nom:this.itemForm.ref,
                            unite:this.itemForm.unite,
                            description:this.itemForm.description,
                            article_id:this.itemForm.article_id,
                            projet_article_id:this.idArticle,


                                })
                                .then(response => {

                                    this.isLoading=false;
                                    this.listEntresProc.unshift(response.data.data);

                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })
                                
                                this.$bvModal.hide('articlesModal')

                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });

                }

        },
        handleSubmitsorti(){


              this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.itemFormsorti.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                            this.isLoading=true;

                            this.$http.post('/projetSortis',{
                            nom:this.itemFormsorti.nom,
                            unite:this.itemFormsorti.unite,
                            description:this.itemFormsorti.description,
                            projet_enter_id:0,
                            is_pert:this.itemFormsorti.is_pert,
                            is_fini:this.itemFormsorti.is_fini,
                            projet_article_id:this.idArticle,


                                })
                                .then(response => {

                                    this.isLoading=false;
                                    this.listSortiesProc.unshift(response.data.data);

                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })
                                
                                this.$bvModal.hide('sortiModal')

                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });

                           
                }


 

    

        },

        addEntre_click()
        {

        this.itemForm.id=''
        this.itemForm.ref=''
        this.itemForm.unite='',
        this.itemForm.description='',
        this.itemForm.article_id='',

               this.$root.$emit('bv::show::modal', 'articlesModal' )
        },

        addSorti_click() {

                            this.itemFormsorti.nom=''
                            this.itemFormsorti.unite='U'
                            this.itemFormsorti.description=''
                            this.itemFormsorti.is_pert=false
                            this.itemFormsorti.is_fini=true
                            this.$root.$emit('bv::show::modal', 'sortiModal' )
        },




        DellSorties_click(id_art)
        {
                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {

            this.isLoading=true;

            this.$http.delete('/projetSortis/'+id_art)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.listSortiesProc.findIndex(item => item.id === id_art)

                    this.listSortiesProc.splice(index, 1)
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });






                })
                .catch(error=> {
                console.log(error)

                });
        },

        DellEntres_click(ite)
        {
            this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
            .then(() => {

            this.isLoading=true;

            this.$http.delete('/projetEnters/'+ite.id)
                .then(response => {
                console.log(response.data)

                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.listEntresProc.findIndex(item => item.id === ite.id)

                    this.listEntresProc.splice(index, 1)
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });






                })
                .catch(error=> {
                console.log(error)

                });
        },


        fetchListProc_entrees()
        {
                    this.isLoading=true;
                    this.$http.get('/entersProjetArticle/'+this.idArticle)
                    .then((result) => {
                                this.listEntresProc = result.data.data

                                this.isLoading=false;

                                //effacer le search:
                                this.serchArt=''
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });



        },

 
        fetchListProc_sorties()
        {
                    this.isLoading=true;
                    this.$http.get('/sortisProjetArticle/'+this.idArticle)
                    .then((result) => {
                                this.listSortiesProc = result.data.data

                                this.isLoading=false;

                                //effacer le search:
                                this.serchArt=''
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });



        },
        NewBon()
        {
                    this.$router.push({ 
                        name: 'projetArticle'

                    });

        },
        AfficherTousArts()
        {
        this.cat_click(0)
        },

        },
        components: {
                    Loading      
        }


}
</script>

<style>
/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>