<template>
  

<div>

 <b-modal  id="uploadimgmodal" title="Image"  size="lg" hide-footer>



    <div class="container ">
        <div class="row justify-content-center">
            <div class="col">
    

                        <div id="preview">
                        <img id="sss" alt="imgs" v-if="url" :src="url" />
                        </div>

                        <div id="preview">
                        <img id="sss" alt="imgs" v-if="rep_url" :src="rep_url" />
                        </div>
               
                        <div v-if="success != ''" class="alert alert-success" role="alert">
                          {{success}}
                        </div>
                        <form @submit.prevent="formSubmit" enctype="multipart/form-data">





                            <div v-if="rep_url==null && img_name==null" class="mt-3 input-group">
                            <div class="custom-file">
                                <input type="file" accept="image/*" v-on:change="onFileChange" class="custom-file-input" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04">
                                <label class="custom-file-label" for="inputGroupFile04">Sélectionnez Une Image</label>
                            </div>
                            <div v-if="url" class=" input-group-append">
                                <button class="btn btn-success" type="submit" id="inputGroupFileAddon04">Valider</button>
                            </div>
                            </div>




                        </form>

                        <div class="mt-3 form-group">
                            <button v-if="rep_url && img_name&& id_bon" @click="supprimer_image" type="button" class="btn  btn-danger btn-block">Supprimer</button>
                        </div>
                        
           
            </div>
        </div>
    </div>





</b-modal>


    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>
</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Compressor from 'compressorjs';

export default {
 props: ['id_bon','rep_url','url','img_name','typ'],

      computed: {

    /*    total_price: function() {
      return this.LotsList.reduce((a, b) => +a + +b.qte, 0);
    }*/
  },

	data () {
		return {
            

            isSubmitted: false,
            isLoading: false,



            email: "",
            password: "",
            error: "",
            name: '',
            file: '',
            compre_file:null,
            success: '',


		}
	},
    validations: {


    },
    methods :{
            onFileChange(e){
              console.log(e.target.files[0]);
              this.file = e.target.files[0];
              this.url = URL.createObjectURL(this.file);
            this.rep_url=null 
            },
       formSubmit() {
               //e.preventDefault();

                      const config = {
                          headers: { 'content-type': 'multipart/form-data' },
                      }

                        new Promise((resolve, reject) => {
                          new Compressor(this.file, {
                            quality: 0.2,
                            success: resolve,
                            error: reject,
                          });
                        }).then((result) => {
                          console.log('Compress success');
                                const myfile = new File([result],result.name,{
                                  type:result.type
                                })
                                let formData = new FormData();
                                formData.append('file', myfile);
                                formData.append('type', this.typ);
                                formData.append('name', this.id_bon.toString());
                                formData.append('id_bon', this.id_bon);

                            
                                // Send the compressed image file to server with XMLHttpRequest.
                                this.send_to_server(formData,config);
                        }).catch((err) => {
                            this.flashMessage.show({
                            status: 'error',
                            title: 'Erreur De Sauvegarde',
                            message: 'Impossible  de compresser la photo .. Réessayez !!'
                            })
                          window.alert(err.message);
                        })
   

            },




        send_to_server(frmdata,cofigo)
        {
          this.isLoading=true;
        this.$http.post('/imageSubmit', frmdata, cofigo)
                      .then(response => {
                          this.isLoading=false;


                this.url = null;
                this.rep_url=response.data.data 
                this.img_name=response.data.name

                      this.flashMessage.show({
                            status: 'success',
                            title: 'Données sauvegardées',
                            message: 'Les données sont sauvegardées correctement '
                        })
                      })
                      .catch(error=> {
                          console.log(error);
                           this.isLoading=false;

                                          this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                      });
        },

        supprimer_image()
        {
                    this.$confirm("Voulez-vous vraiment supprimer cet image ??")
                    .then(() => {

                                    this.imageDell()
                                })
                                .catch(error=> {
                                console.log(error)

                                    });
        },
        imageDell()
        {
                    this.isLoading=true;
                    this.$http.get('/dellImage/'+this.typ+'/'+this.img_name+'/'+this.id_bon)
                    .then((result) => {


                            if(result.data.data=='ok' || result.data.data=='no' )
                            {
                                    this.url = null
                                    this.rep_url=null 
                                    this.img_name=null
                            }


                                this.isLoading=false;


                                                this.flashMessage.show({
                                                status: 'success',
                                                title: 'Données Supprimées',
                                                message: 'Les données sont supprimées correctement'
                                                })
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                 this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                    });
        }
    }

    ,
    components: {
              Loading,
             
    }
}
</script>

<style>

#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>