<template>
<div>
  
                        
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h4 class="col ml-3 font-weight-lighter var_color"> <span class="fa fa-project-diagram"></span> Projets (Terminés)</h4> 


                    <div class="col">
         

                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

                                    <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actualiser</span> 
                                    </b-button>
                    </div>
            </div>
       



        <b-table 
                id="main-table"
        :per-page="perPage"
        :current-page="currentPage"
        responsive 
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">

                    <b-button pill size="sm" class="mr-2 mt-1 mb-1  float-right" variant="outline-success" @click="edit_click(row.item)">
                            <i class="fa fa-folder-open"></i>
                             Détails
                    </b-button>

                </template>
        
        </b-table>
        
                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>
      </div>




    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {
      computed: {

      rows_pagin() {
        return this.itemslist.length
      }
      },
    mounted(){
        this.fetchList()
    },
	data () {
		return {
                        perPage: 10,
            currentPage: 1,
            itemslist: [],



            fields: [ 
				{key: 'nom', label: 'Nom', sortable: true},
                {key: 'client', label: 'Client', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'date_livraison', label: 'Date De Livraison', sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                {key: 'user', label: 'Utilisateur', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs

            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                }
            }
    },
    methods :{

        edit_click(item) {

                this.$confirm("Voulez-vous vraiment afficher les détails de ce projet ??")
                 .then(() => {



                        this.$router.push({ 
                        name: 'projetPageDetail', 
                        params: { 
                        idBon: item.id,
                        nClient: item.client,
                        } 
                        });


                })
                .catch(error=> {
                console.log(error)

                });


        },

        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/projetType/3')
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuProjet'
                    });
        },

    },
    components: {
              Loading      
    }
}
</script>

<style>
.var_color{
  color: #2faaa5;
}
</style>