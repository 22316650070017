<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





        <form @submit.prevent="handleSubmitBonCMD()">
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">
                           
                            <label for="client_id" class="mr-sm-2 pt-2"> Client :</label>

                            <select class="form-control" id="client_id" v-model="itemFormBon.client_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.client_id.$error }">
                            <option v-for="item in ListClients" :key="item.id" v-bind:value="item.id">   
                               {{ item.nom_prenom }} - {{ item.code }}
                            </option>

                            <option value=0>Tous</option>

                            </select>


        
                <label for="dateDebut" class="mr-sm-2  pt-2 pl-1"> De :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateDebut"
                    placeholder="date fin"
                    v-model="itemFormBon.dateDebut"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
        

                       
                <label for="dateFin" class="mr-sm-2  pt-2 pl-1"> Au :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateFin"
                    placeholder="date fin"
                    v-model="itemFormBon.dateFin"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
      



                                    <div class="input-group-prepend">
                                        <button class="btn  btn-info rounded-pill ml-1" type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                                

                </div>
        </form>





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Liste Des Produits Finis (Livrés)</h4> 


                    <div class="col">

                                    <b-button @click="exportDox" pill size="sm"  class="m-2 float-right" variant="info">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>

                    </div>
            </div>
       

  <div class="row">
            <div class="col">

                    <b-table 


                    id="main-table"
                    :per-page="perPage"
                    :current-page="currentPage"
                    responsive 
                    sort-icon-left
                    head-variant="light"
         
                    :fields="fields" 
                    :items="itemslist"
                    >
                
                    </b-table>
                    <b-pagination
                    pills
                    v-model="currentPage"
                    :total-rows="rows_pagin"
                    :per-page="perPage"
                    aria-controls="main-table"
                    align="center"
                    ></b-pagination>
            </div>



        
  </div>

      </div>





    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
      computed: {

                    
                entet_pag() {

      if(this.$store.getters.get_entete)
      {
            return this.$store.getters.get_entete;
      }
      else
      {
            return '';
      }
    },
    sousentet_pag() {

      if(this.$store.getters.get_sousentete)
      {
            return this.$store.getters.get_sousentete;
      }
      else
      {
            return '';
      }
    },
    pied_pag() {

      if(this.$store.getters.get_pied)
      {
            return this.$store.getters.get_pied;
      }
      else
      {
            return '';
      }
    }, 
      rows_pagin() {
        return this.itemslist.length
      },

    },
    mounted(){
      this.fetchCentre()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            perPageav: 10,
            currentPageav: 1,
            itemFormBon: {
                    client_id:'',
                    centre_nom:'',
                    dateDebut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    dateFin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),

            },

            ListClients:[],
            itemslist: [],
            fields: [
				{key: 'ref', label: 'Référence', sortable: true},
				{key: 'dim', label: 'Dimensions', sortable: true},
                {key: 'total_qte', label: 'Total Quantité', sortable: true},
                {key: 'unite', label: 'Unité', sortable: true},

            ],



            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {
                client_id: {
                    required
                },
                dateDebut: {
                    required
                },
                dateFin: {
                    required
                }
            }

    },
    methods :{

  
exportDox()
        {


        let  cent= ""
 
        if(this.itemFormBon.client_id==0)
        {
        cent="Tous"
        }
        else
        {
        let mm=this.ListClients.findIndex(item => item.id === this.itemFormBon.client_id)
        cent= this.ListClients[mm].nom_prenom + " - "+this.ListClients[mm].code
        }
 
//paiements
        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                children: [
                                                                        new TextRun({
                                                                        text: "Référence",
                                                                        color: '0f31b5',
                                                                        bold: false,
                                                                        font: "Segoe UI"
                                                                        }),
                                                                    ],
                                            spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                            children: [
                                                                        new TextRun({
                                                                        text: "Dimensions",
                                                                        color: '0f31b5',
                                                                        bold: false,
                                                                        font: "Segoe UI"
                                                                        }),
                                                                    ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                                                new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                            children: [
                                                                        new TextRun({
                                                                        text: "Total Quantité",
                                                                        color: '0f31b5',
                                                                        bold: false,
                                                                        font: "Segoe UI"
                                                                        }),
                                                                    ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                                                width: {
                                                    size: 4000,
                                                    type: WidthType.DXA,
                                                },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        children: [
                                                                        new TextRun({
                                                                            text: "Unité",
                                                                        color: '0f31b5',
                                                                        bold: false,
                                                                        font: "Segoe UI"
                                                                        }),
                                                                    ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })]

        this.itemslist.forEach(function(item){




        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                children: [
                                                    new TextRun({
                                                        text: item.ref,
                                                        color: '000000',
                                                        bold: true,
                                                        }),
                                                            ],
                                        spacing: {
                                        before: 50,
                                        after: 50
                                    },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                children: [
                                                            new TextRun({
                                                                text: item.dim,
                                                                color: '000000',
                                                                bold: true,
                                                                }),
                                                                    ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                children: [
                                                            new TextRun({
                                                                text: item.total_qte.toString(),
                                                                color: '000000',
                                                                bold: true,
                                                                }),
                                                                    ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 4000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                children: [
                                        new TextRun({
                                            text: item.unite,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                    ],
                })

        datrw.push(
        r
        )
        });

        const table = new Table({

            rows:  datrw
        })




        const doc = new Document({

    sections: [
        {


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                            margin:{
                                left:500,
                                right:500
                            }
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.entet_pag,
                                color: '000000',
                                bold: true,

                                }),
                                    ],
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.sousentet_pag,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                                })

                                    ],
                                border: {
                                bottom: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                }),




                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.pied_pag,
                                color: '000000',
                                bold: false,
                                })

                                    ],
                                border: {
                                top: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                }),

                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                            color: '000000'
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                            color: '000000'
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },




                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Liste Des Produits Finis (Livrés)",
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            })

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),



                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Client : "+cent,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_3,
                            alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 100
                                },
                        }),




                        table,


           


                        
                        ]


        }]



                });








            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "Produits_finis_Projets.docx");

            });

        },




                fetchCentre: function () {



                    //chager les clients:
                    this.isLoading=true;
                    this.$http.get('/clientMinDeZone/0')
                    .then((result) => {
                                this.ListClients = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },

                handleSubmitBonCMD(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchListLivraison(this.itemFormBon.client_id,this.itemFormBon.dateDebut,this.itemFormBon.dateFin)
   


                }


        },

        fetchListLivraison: function (idfour,deb,fin) {
                    this.isLoading=true;
                    
                    this.$http.get('/projetsBlQteRapp/'+idfour+'/'+deb+'/'+fin)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },



    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>