<template>
  

<div  class="card rounded-lg shadow border border-white bg-white h-100 mb-0 pb-3 overflow-auto" >
    <div class="card-header bg-transparent">
             
           

<div class="row">


    <div class="col">
        

        <h5 class="ml-2 text-secondary font-weight-light"> <span class="fa fa-file-alt"></span> Projet : <span class="ml-2 text-success"> {{nomBon}} </span> </h5>
        

        <h6 class="mt-1 ml-2 text-secondary font-weight-normal">Client : <span class=" text-info"> {{nClient}} </span>  </h6>

        <div class="mt-1 ml-2 text-secondary font-weight-normal">Etat Du Projet : <span class="text-info"> {{ui_etat_projet}} </span>  </div>


                <b-button  class=" ml-2 mt-2 p-0 text-warning text-decoration-none" variant="link" @click="GetContactAndShow">
                    <i class="fa fa-id-card "></i>
                    <span> Contacts Du Projet</span> 
                </b-button>


    </div>
    <div v-if="d_gst_proj_cout_prix" class="col">


        <h6 class="ml-2 text-secondary font-weight-normal"> Coût Global : <span class="ml-2 mr-2 text-dark"> {{total_cout_global}} DH</span></h6>
        <h6 class="ml-2 text-secondary font-weight-normal"> Livraisons : <span class="ml-2 mr-2 text-info"> {{total_livraison}} DH</span></h6>
        <h6 class="ml-2 text-secondary font-weight-normal"> Bénéfice : <span class="ml-2 mr-2 text-success"> {{total_benefice}} DH</span></h6>


 </div>
    <div class="col">

                <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                            <i class="fa fa-home fa-2x"></i>
                </b-button>


                <b-button v-if="etatProjet== 0 && is_projet_charg==true" pill size="sm"  class="m-2 float-right" variant="info" @click="clickValiderBon(1)">
                    <i class="fa fa-check"></i>
                    <span> En Cours</span> 
                </b-button>

                <b-button v-if="etatProjet== 3  && is_projet_charg==true" pill size="sm"  class="m-2 float-right" variant="info" @click="clickValiderBon(1)">
                    <i class="fa fa-check"></i>
                    <span> En Cours</span> 
                </b-button>


                <b-button  v-if="d_gst_proj_valider_proj && etatProjet== 1 && is_projet_charg==true && nbr_etape_encours==0 && nbr_bls_encours==0" pill size="sm"  class="m-2 float-right" variant="success" @click="clickValiderBon(3)">
                    <i class="fa fa-check"></i>
                    <span> Terminé</span> 
                </b-button>


                <b-button v-if="d_gst_proj_valider_proj && etatProjet== 1 && is_projet_charg==true" pill size="sm"  class="m-2 float-right" variant="secondary" @click="clickValiderBon(0)">
                    <i class="fa fa-check"></i>
                    <span> Brouillon</span> 
                </b-button>


                <b-button v-if="d_gst_proj_creer_proj && etatProjet== 0 && is_projet_charg==true"  pill size="sm"  class="m-2 float-right" variant="danger" @click="del_projet_click">
                    <i class="fa fa-trash-alt"></i>
                    <span> Supprimer</span> 
                </b-button>


    </div>

</div>


    </div>




    <div class="row card-body h-100 p-0 m-0">



                <div v-if="etatProjet!= 0" class="col h-100 overflow-auto mt-2 mr-0 ml-0">



                        <div v-if="d_gst_proj_etap" class="row ">

                                                    <div class="col-12 overflow-auto">
                                                                    <b-button v-if="etatProjet== 1" pill size="sm"  class="m-2  float-right" variant="success"  @click="addSorti_click">
                                                                        <i class="fa fa-plus"></i>
                                                                        <span> Ajouter </span> 
                                                                    </b-button>
                                                                    <div class="mt-2 ml-0 text-danger h5">Etapes </div>
                                                    </div>

                                            
                                                    <div class="col-12 border border-light shadow">





                                                            <b-table 
                                                            sort-icon-left
                                                            small 
                                                            responsive 
                                                            :tbody-tr-class="rowClassEtap"
                                                            :fields="fieldsEtapes" 
                                                            :items="listEtapes"
                                                            >



                                                    <template #head(nom)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 
                                                    <template #head(date_debut)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 
                                                    <template #head(date_fin)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 
                                                    <template #head(total_ttc)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 
                                                    <template #head(description)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 
                                            <template #cell(actions)="row">
                                            <b-button v-if="row.item.etat==0" pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="del_click(row.item)">
                                                <i class="fa fa-trash-alt"></i>
                                                                            
                                            </b-button>

                                            <b-button v-if="row.item.etat==0" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="edit_click(row.item, row.index, $event.target)">
                                                <i class="fa fa-pencil-alt"></i>
                                            
                                            </b-button>


                                            <b-button pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-info" @click="edit_detail_etap(row.item)">
                                                    <i class="fa fa-tasks"></i>
                                                    Détails
                                            </b-button>
                                        </template>
                                                            
                                        </b-table>
                                        <div v-if="d_gst_proj_cout_prix" class=" d-flex justify-content-center pt-1 text-dark">
                                            <h5 class="font-weight-normal">Total : {{total_etape}} DH</h5>
                                        </div>

                                                            
                                                    </div>
                        </div>

                                            
                    <div v-if="d_get_gst_proj_bn_charg" class="row mt-4">

                            <div class="col-12 overflow-auto">
                                            <b-button v-if="etatProjet== 1"  pill size="sm"  class="m-2  float-right" variant="success"  @click="add_charge_click">
                                                <i class="fa fa-plus"></i>
                                                <span> Ajouter </span> 
                                            </b-button>
                                            <div class="mt-2 ml-0 text-primary h5">Bons De Charge </div>
                            </div>


                                <div class="col-12 border border-light shadow">



                                    

                                        <b-table 
                                        small 
                                        responsive 
                                        :tbody-tr-class="rowClassbl"
                                        :fields="fieldscharg" 
                                        :items="itemslistcharg"
                                        >


                                <template #head(nom)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template> 
                                <template #head(date)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template> 
                                <template #head(total_ttc)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template> 
                                <template #head(description)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template> 
                                <template #head(user)="data">
                                    <span class="text-info font-weight-light">{{ data.label }}</span>
                                </template> 
                                <template #cell(actions)="row">
                                <b-button v-if="row.item.valider==0"  pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="del_bncharg_click(row.item)">
                                    <i class="fa fa-trash-alt"></i>                 
                                </b-button>

                                <b-button v-if="row.item.valider==0" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="edit_bncharg_click(row.item, row.index, $event.target)">
                                <i class="fa fa-pencil-alt"></i>

                                </b-button>


                                <b-button   pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-info" @click="edit_detail_bncharg(row.item)">
                                <i class="fa fa-tasks"></i>
                                Détails
                                </b-button>
                                            </template>
                                        
                                        </b-table>

                                </div>
                    </div>


                        <div v-if="d_gst_proj_bl" class="row mt-4">

                                                    <div class="col-12 overflow-auto">
                                                                    <b-button v-if="etatProjet== 1"  pill size="sm"  class="m-2  float-right" variant="success"  @click="add_bl_click">
                                                                        <i class="fa fa-plus"></i>
                                                                        <span> Ajouter </span> 
                                                                    </b-button>
                                                                    <div class="mt-2 ml-0 text-success h5">Bons De Livraison </div>
                                                    </div>

                                            
                                                    <div class="col-12 border border-light shadow">





                                                            <b-table 
                                                            small 
                                                            responsive 
                                                            :tbody-tr-class="rowClassbl"
                                                            :fields="fieldsbl" 
                                                            :items="itemslistbl"
                                                            >


                                                    <template #head(nom)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 
                                                    <template #head(date)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 
                                                    <template #head(total_ttc)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 
                                                    <template #head(description)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 
                                                    <template #head(user)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 
                                                    <template #cell(actions)="row">
                                             <b-button v-if="row.item.valider==0"  pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="del_bl_click(row.item)">
                                                         <i class="fa fa-trash-alt"></i>                 
                                             </b-button>

                                            <b-button v-if="row.item.valider==0" pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="edit_bl_click(row.item, row.index, $event.target)">
                                                    <i class="fa fa-pencil-alt"></i>
                                            
                                            </b-button>


                                            <b-button   pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-info" @click="edit_detail_bl(row.item)">
                                                    <i class="fa fa-tasks"></i>
                                                    Détails
                                            </b-button>
                                                                </template>
                                                            
                                                            </b-table>

                                                    </div>
                        </div>



                        <div v-if="d_gst_proj_facture" class="row mt-4">

                                                    <div class="col-12 overflow-auto">
                                                                    <b-button v-if="etatProjet== 1" pill size="sm"  class="m-2  float-right" variant="success"  @click="fetchFournisShowFacture">
                                                                        <i class="fa fa-plus"></i>
                                                                        <span> Ajouter </span> 
                                                                    </b-button>
                                                                    <div class="mt-2 ml-0 text-dark h5">Factures / Livraisons Fournisseur </div>
                                                    </div>

                                            
                                                    <div class="col-12 border border-light shadow">

                                                            <b-table 
                                                            sort-icon-left
                                                            small 
                                                            responsive 
                                                            :tbody-tr-class="rowClassEtap"
                                                            :fields="fieldsFacture" 
                                                            :items="listFacture"
                                                            >


                                                    <template #head(nom)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 
                                                    <template #head(date)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 

                                                    <template #head(total_ttc)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 

                                                    <template #head(fournisseur)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 

                                                    <template #head(description)="data">
                                                        <span class="text-info font-weight-light">{{ data.label }}</span>
                                                    </template> 
                                            <template #cell(actions)="row">
                                            <b-button  v-if="etatProjet== 1" pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="del_factur_four_click(row.item)">
                                                <i class="fa fa-trash-alt"></i>
                                                                            
                                            </b-button>

                                            <b-button v-if="etatProjet== 1"  pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="edit_fact_four_click(row.item, row.index, $event.target)">
                                                <i class="fa fa-pencil-alt"></i>
                                            
                                            </b-button>
                                            <b-button  pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-success" @click="clic_add_uplode_image(row.item)">
                                                    <i class="fa fa-image"></i>
                                            </b-button>

                                                                </template>
                                                            
                                                            </b-table>

                                                            <div v-if="d_gst_proj_cout_prix" class=" d-flex justify-content-center pt-1 text-dark">
                                                                    <h5 class="font-weight-normal">Total : {{total_facture}} DH</h5>
                                                            </div>
                                                            
                    
                                                    </div>
                        </div>



                </div>



  </div>



    <!-- le modal -->
    <b-modal id="sortiModal" title="Etapes"  hide-footer>
           <form @submit.prevent="handleSubmitEtape">
            <div class="form-group">
                <label for="nom">Nom :</label>
                <input type="text" v-model="itemFormEtape.nom" id="nom" name="nom" class="form-control"
                :class="{ 'is-invalid': isSubmitted && $v.itemFormEtape.nom.$error }" />
                <div v-if="isSubmitted && !$v.itemFormEtape.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>


                        <div class="form-group">
                            <label for="date_debut" class="mr-sm-2">Date De Début:</label>
                                <b-form-datepicker
                                class="mr-sm-2"
                                id="datepicker-date_debut"
                                placeholder="date_debut"
                                v-model="itemFormEtape.date_debut"
                                :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                                locale="fr" />
                        </div>


                        <div class="form-group">
                            <label for="date_fin" class="mr-sm-2">Date De Fin:</label>
                                <b-form-datepicker
                                class="mr-sm-2"
                                id="datepicker-date_fin"
                                placeholder="date"
                                v-model="itemFormEtape.date_fin"
                                :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                                locale="fr" />
                        </div>


            <div class="form-group">
            <label for="Description">Description</label>
            <textarea name="description" id="description"  rows="4" class="form-control" v-model="itemFormEtape.description"></textarea>
            </div>

            <div class="form-group mt-4">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>


        </form>
    </b-modal>


    <!-- le modal -->
    <b-modal id="blModal" title="Bon De Livraison"  hide-footer>


           <form @submit.prevent="handleSubmitBonCMD()">

            <div class="row  mt-2">
            <div class="form-group col">
                <label for="qte">N° Bon  :</label>
                <input :disabled="nom_dis" type="text" v-model="itemFormBon.nom" id="qte" name="qte" class="form-control"
                 :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.nom.$error }"/>
                 
                <div v-if="isSubmitted && !$v.itemFormBon.nom.required" class="invalid-feedback">Entrez Le N° de bon !!</div>


            </div>


            <div class="form-group col">
                <label for="date_exp" class="mr-sm-2">Date :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="itemFormBon.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>


            </div>


            <div class="form-group mt-2">
            <label for="adresse">Note :</label>
            <textarea name="adresse" id="adresse"  rows="2" class="form-control" v-model="itemFormBon.description"></textarea>
            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Valider</button>
            </div>

        </form>


    </b-modal>


    <b-modal  id="contactmodal" title="Contacts" size="lg"  hide-footer>

                <form v-if="etatProjet== 1" @submit.prevent="handleSubmitContact">
                    <div class="row">
                        <div class="col">
                                <div class="form-group">
                                    <label class="form-control-sm mb-0" for="name">Nom :</label>
                                    <input type="text" v-model="itemFormContact.nom" id="name" name="name" class="form-control form-control-sm"
                                        :class="{ 'is-invalid': isSubmitted && $v.itemFormContact.nom.$error }" />
                                    <div v-if="isSubmitted && !$v.itemFormContact.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                </div>

                        </div>

                        <div class="col">
                                <div class="form-group">
                                    <label class="form-control-sm mb-0" for="tel">Téléphone :</label>
                                    <input type="text" v-model="itemFormContact.tel" id="tel" name="tel" class="form-control form-control-sm" />

                                </div>
                        </div>

                        
                        <div class="col">
    
                                <div class="form-group">
                                    <label class="form-control-sm mb-0" for="email">Email :</label>
                                    <input type="text" v-model="itemFormContact.email" id="email" name="email" class="form-control form-control-sm" />

                                </div>
                        </div>
                    </div>



                            <div class="form-group">
                                <textarea name="description" id="description" placeholder="Note ..."  rows="2" class="form-control form-control-sm" v-model="itemFormContact.note"></textarea>
                                </div>

                <div class="form-group">
                    <button type="submit" class="btn  btn-primary btn-block">Ajouter</button>
                </div>

            </form>



            <b-table 
            sort-icon-left
            small 
            sticky-header
            :fields="fieldsContact" 
            :items="listContact"
            >
                <template #cell(actions)="row">
                        <b-button v-if="etatProjet== 1" pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_contactclick(row.item)">
                                    <i class="fa fa-trash-alt"></i>
                            
                        </b-button>
                </template>
            
            </b-table>


    </b-modal>


    <b-modal  id="facturemodal" title="Facture" size="lg"  hide-footer>

           <form @submit.prevent="handleSubmitFacture()">

            <div class="row  mt-2">
            <div class="form-group col">
                <label for="qte">N° Bon /Facture :</label>
                <input type="text" v-model="itemFacture.nom" id="qte" name="qte" class="form-control"
                 :class="{ 'is-invalid': isSubmitted && $v.itemFacture.nom.$error }"/>
                 
                <div v-if="isSubmitted && !$v.itemFacture.nom.required" class="invalid-feedback">Entrez Le N° de la facture /bon Fournisseur!!</div>


            </div>


            <div class="form-group col">
                <label for="date_exp" class="mr-sm-2">Date :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="itemFacture.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>


            </div>



            <div class="row mt-2">
                        <div class="form-group col">

                            <label for="fournisseur_id">Fournisseur :</label>
                            <select class="form-control" id="fournisseur_id" v-model="itemFacture.fournisseur_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFacture.fournisseur_id.$error }">
                            <option v-for="item in fournisseurs" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemFacture.fournisseur_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                         </div>

                                <div class="form-group col">
                                    <label for="qte_min" >Total TTC : </label>
                                    <input type="text" v-model="itemFacture.total_ttc" id="qte_min" name="qte_min" class="form-control"
                                        :class="{ 'is-invalid': isSubmitted && $v.itemFacture.total_ttc.$error }" />
                                    <div v-if="isSubmitted && !$v.itemFacture.total_ttc.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                    <div v-if="isSubmitted && !$v.itemFacture.total_ttc.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                                </div>

            </div>


            <div class="form-group mt-2">
            <label for="adresse">Note :</label>
            <textarea name="adresse" id="adresse"  rows="2" class="form-control" v-model="itemFacture.description"></textarea>
            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Suivant</button>
            </div>

        </form>


    </b-modal>


    <!-- le modal -->
    <b-modal id="bnchargModal" title="Bon De Charge"  hide-footer>


<form @submit.prevent="handleSubmitBonCharg()">

 <div class="row  mt-2">
 <div class="form-group col">
     <label for="qte">N° Bon  :</label>
     <input :disabled="nom_dis" type="text" v-model="itemFormBoncharg.nom" id="qte" name="qte" class="form-control"
      :class="{ 'is-invalid': isSubmitted && $v.itemFormBoncharg.nom.$error }"/>
      
     <div v-if="isSubmitted && !$v.itemFormBoncharg.nom.required" class="invalid-feedback">Entrez Le N° de bon !!</div>


 </div>


 <div class="form-group col">
     <label for="date_exp" class="mr-sm-2">Date :</label>
         <b-form-datepicker
         class="mr-sm-2"
         id="datepicker-date_exp"
         placeholder="date"
         v-model="itemFormBoncharg.date"
         :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
         locale="fr" />
 </div>


 </div>


 <div class="form-group mt-2">
 <label for="adresse">Note :</label>
 <textarea name="adresse" id="adresse"  rows="2" class="form-control" v-model="itemFormBoncharg.description"></textarea>
 </div>


 <div class="form-group">
     <button type="submit" class="btn  btn-primary btn-block">Valider</button>
 </div>

</form>


</b-modal>




      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>
        
      <uploadBonImg v-bind:id_bon="itemFormImage.id_bon"
        :rep_url="itemFormImage.rep_url"
        :url="itemFormImage.url"
        :img_name="itemFormImage.img_name"
        :typ="itemFormImage.typ"
        ></uploadBonImg>
    </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required, decimal} from "vuelidate/lib/validators";
import uploadBonImg from '@/components/UploadImg/UploadBonImg.vue';


export default {
 props: ['idBon','nClient'],

 
    computed: {

          d_gst_proj_creer_proj() {
      return this.$store.getters.get_gst_proj_creer_proj;
    },
          d_gst_proj_valider_proj() {
      return this.$store.getters.get_gst_proj_valider_proj;
    },
          d_gst_proj_etap() {
      return this.$store.getters.get_gst_proj_etap;
    },
    d_gst_proj_facture() {
      return this.$store.getters.get_gst_proj_facture;
    },
    

    d_gst_proj_bl() {
      return this.$store.getters.get_gst_proj_bl;
    },
    d_get_gst_proj_bn_charg() {
      return this.$store.getters.get_gst_proj_bn_charg;
    },


    d_gst_proj_cout_prix() {
      return this.$store.getters.get_gst_proj_cout_prix;
    },

      ui_etat_projet() {
                        if(this.etatProjet==0)
                        {
                            return 'Brouillon'
                        }
                        else if(this.etatProjet==1)
                        {
                            return 'En Cours'
                        }
                        else if(this.etatProjet==3)
                        {
                            return 'Términé'
                        }
                        else
                        {
                            return ''
                        }


    },

    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },


    total_etape: function() {
      return this.listEtapes.reduce((a, b) => +a + +(b.total_ttc), 0)
    },

    total_facture: function() {
      return this.listFacture.reduce((a, b) => +a + +(b.total_ttc), 0)
    },

    total_cout_global: function() {
        return this.total_etape + this.total_facture;
    },

    total_livraison: function() {
      return this.itemslistbl.reduce((a, b) => +a + +(b.total_ttc), 0)
    },
    total_benefice: function() {
      return this.total_livraison - this.total_cout_global;
    },
    nbr_etape_encours: function() {
    return this.listEtapes.filter(({etat}) => etat === 0).length;
    },

    nbr_bls_encours: function() {
    return this.itemslistbl.filter(({valider}) => valider === 0).length;
    },

    },
    mounted(){


        this.fetchInitprojetInfos(this.idBon)

    

    if(this.d_gst_proj_cout_prix)
    {
        this.fieldsEtapes=this.fieldsEtapesPrix;
    }


    },
    data () {
		return {
//charge
        itemFormBoncharg: {
                    id:"",
                    nom:'B-CH',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    valider:false,
                    description:'',
                    client_id:'',
                    projet_id:''},

        itemslistcharg: [],

        fieldscharg: [
            {key: 'nom', label: 'N°'},
            {key: 'date', label: 'Date'},
            {key: 'description', label: 'Description'},
            {key: 'user', label: 'Utilisateur'},
            { key: 'actions', label: '' }
        ],


//fin charge

//facture
            itemFacture: {
                    
                nom:'Livraison',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    total_ttc:0,
                    description:'',
                    fournisseur_id:''},

            fournisseurs:[],


            listFacture: [],
            fieldsFacture: [
                {key: 'nom', label: 'Nom'},
                {key: 'date', label: 'Date'},
                {key: 'total_ttc', label: 'Total TTC'},
                {key: 'fournisseur', label: 'Fournisseur'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],

//fin facture

            //contacts
            itemFormContact: {
                    nom:'',
                    tel:'',
                    email:'',
                    note:''},

            listContact: [],
            fieldsContact: [
                {key: 'nom', label: 'Nom'},
                {key: 'tel', label: 'Téléphone'},
                {key: 'email', label: 'Email'},
                {key: 'note', label: 'Note'},
                { key: 'actions', label: '' }
            ],

            //fin contact
            nomBon:'',
            idClient:'',
            ddate:'',
            ddateLivraison:'',
            idDep:'',
            etat:'',
            is_projet_charg:false,




            itemFormBon: {
                    id:"",
                    nom:'BL-C',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    valider:false,
                    regler:false,
                    total_ttc:0,
                    description:'',
                    client_id:'',
                    projet_id:''},

            etatProjet:'',
            itemFormEtape:{
                    id:'',
                    nom:'',
                    date_debut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    date_fin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    description:'',
                    etat:0},

            listEtapes: [],

            fieldsEtapesPrix: [
                {key: 'nom', label: 'Nom'},
                {key: 'date_debut', label: 'Date Début'},
                {key: 'date_fin', label: 'Date Fin'},
                {key: 'total_ttc', label: 'Coût Total'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],

            fieldsEtapes: [
                {key: 'nom', label: 'Nom'},
                {key: 'date_debut', label: 'Date Début'},
                {key: 'date_fin', label: 'Date Fin'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],




            itemslistbl: [],

            fieldsbl: [
                {key: 'nom', label: 'N°'},
                {key: 'date', label: 'Date'},
                {key: 'total_ttc', label: 'Total TTC'},
                {key: 'description', label: 'Description'},
                {key: 'user', label: 'Utilisateur'},
                { key: 'actions', label: '' }
            ],

            itemFormImage: {
                    id_bon:'',
                    rep_url:'',
                    url:'',
                    img_name:'',
                    typ:'Facts'
            },

            //public trucs
            isModification:false,
            nom_dis:true,
        
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
    },
    validations: {
            itemFormEtape: {
                nom: {
                    required
                },
                date_debut: {
                    required,
                },
                date_fin: {
                    required,
                },
                etat: {
                    required,
                }

            },
            itemFormBon: {
                nom: {
                    required
                },
                date: {
                    required
                },

            },
            itemFormBoncharg: {
                nom: {
                    required
                },
                date: {
                    required
                },
            },
            itemFormContact: {
                nom: {
                    required
                }

            },
            itemFacture: {
                nom: {
                    required
                },
                date: {
                    required
                },
                total_ttc: {
                    required,
                    decimal
                },
                fournisseur_id: {
                    required
                }
            }

    },
    methods:{
            del_projet_click() {

            this.$confirm("Voulez-vous vraiment supprimer ce projet??")
            .then(() => {

                this.BnprojetDell(this.idBon)
            })
            .catch(error=> {
            console.log(error)

                });
            },
            BnprojetDell(id_bn){

this.isLoading=true;

this.$http.delete('/projets/'+id_bn)
    .then(response => {
    console.log(response.data)
    this.isLoading=false;   
    this.flashMessage.show({
    status: 'success',
    title: 'Données Supprimées',
    message: 'Les données sont supprimées correctement'
    })


    this.$router.push({ 
        name: 'menuProjet'
    });




        })
    .catch(error=> {console.log(error)
    this.isLoading=false;


    this.flashMessage.show({
    status: 'error',
    title: 'Erreur De Suppression',
    message: 'Impossible  de supprimer les données .. Réessayez !!'
    })
    });

},
        //bncharge
        add_charge_click()
        {
                    this.itemFormBoncharg.id="",
                    this.itemFormBoncharg.nom="--",
                    this.itemFormBoncharg.date=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    this.itemFormBoncharg.valider=0,
                    this.itemFormBoncharg.description="",
                    this.itemFormBoncharg.client_id=this.idClient,
                    this.itemFormBoncharg.depot_id=this.idBon



                     //Activer la modification
                    this.isModification=false

                                        //boninfos Champs disibl:
                        this.nom_dis=true,

                    this.$root.$emit('bv::show::modal', 'bnchargModal' )

        },

        handleSubmitBonCharg(){


                                    
            this.isSubmitted = true;

            this.$v.$touch();
            if (this.$v.itemFormBoncharg.$invalid) {
            this.flashMessage.show({
            status: 'info',
            title: 'Vérifiez les données et Reésayez !!',
            })
            }
            else 
            {


                if(this.isModification)
                {

                this.edit_bon_charg()
                }
                else
                {
                
                this.add_bon_charg()
                }
            }
        },
        add_bon_charg()
                {

                    this.isLoading=true;

                    this.$http.post('/ProjetBonCharges',{

                            nom:this.itemFormBoncharg.nom,
                            date:this.itemFormBoncharg.date,
                            valider:0,
                            description:this.itemFormBoncharg.description,
                            projet_id:this.idBon,
                            client_id:this.idClient,
                            user:this.user_name


                        })
                        .then(response => {
                            //metre a jour id
                            this.itemFormBoncharg.id=response.data.data.id,
                            this.itemFormBoncharg.nom=response.data.data.nom,

                            //Activer la modification
                            this.isModification=true,


                            this.isLoading=false;
                            //afficher message
                            this.flashMessage.show({
                            status: 'success',
                            title: 'Données sauvegardées',
                            message: 'Les données sont sauvegardées correctement '


                        })
                            //afficher les details:
                            this.preparerDetailCharge(
                            response.data.data.id,
                            response.data.data.nom,
                            this.nClient,
                            this.idBon,
                            this.nomBon,
                            response.data.data.date,
                            0
                            )

                        })
                        .catch(error=> {console.log(error)
                        this.isLoading=false;


                    this.flashMessage.show({
                        status: 'error',
                        title: 'Erreur De Sauvegarde',
                        message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                        })
                        });


        },
        edit_bon_charg()
                {

                    this.isLoading=true;

                    this.$http.patch('/ProjetBonCharges/'+this.itemFormBoncharg.id,{

                            nom:this.itemFormBoncharg.nom,
                            date:this.itemFormBoncharg.date,
                            valider:this.itemFormBoncharg.valider,
                            description:this.itemFormBoncharg.description,
                            projet_id:this.itemFormBoncharg.projet_id,
                            client_id:this.itemFormBoncharg.client_id,
                            user:this.user_name
                        })
                        .then(response => {

                            this.isLoading=false;
                       

                    this.$bvModal.hide('bnchargModal')

                    let index = this.itemslistcharg.findIndex(item => item.id === response.data.data.id)
                    this.itemslistcharg.splice(index, 1,response.data.data)


                            //afficher message
                            this.flashMessage.show({
                            status: 'success',
                            title: 'Données sauvegardées',
                            message: 'Les données sont sauvegardées correctement '
                        })



                        })
                        .catch(error=> {console.log(error)
                        this.isLoading=false;


                    this.flashMessage.show({
                        status: 'error',
                        title: 'Erreur De Sauvegarde',
                        message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                        })
                        });


        },
        edit_detail_bncharg(itm){
                                    //afficher les details:
                                    this.preparerDetailCharge(
                                    itm.id,
                                    itm.nom,
                                    this.nClient,
                                    this.idBon,
                                    this.nomBon,
                                    itm.date,
                                    itm.valider
                                    )
        },

        del_bncharg_click(itemo) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {


            this.isLoading=true;

            this.$http.delete('/ProjetBonCharges/'+itemo.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslistcharg.findIndex(item => item.id === itemo.id)
                    this.itemslistcharg.splice(index, 1)



                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

                                })
                                .catch(error=> {
                                console.log(error)

                                    });




        },

        edit_bncharg_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {


                    this.itemFormBoncharg.id=item.id,
                    this.itemFormBoncharg.nom=item.nom,
                    this.itemFormBoncharg.date=item.date,
                    this.itemFormBoncharg.valider=item.valider,
                    this.itemFormBoncharg.description=item.description,
                    this.itemFormBoncharg.client_id=item.client_id,
                    this.itemFormBoncharg.projet_id=item.projet_id



                     //Activer la modification
                    this.isModification=true

                        //boninfos Champs disibl:
                        this.nom_dis=false,
                this.$root.$emit('bv::show::modal', 'bnchargModal', button)
                })
                .catch(error=> {
                console.log(error)

                });

        },

        preparerDetailCharge(id_bon,nom,nom_fou,id_prj,nomprj,ddat,val)
        {

                        this.$router.push({ 
                        name: 'projetBnChargeDetail', 
                        params: { 
                        idBon: id_bon,
                        nomBon: nom,
                        nClient: nom_fou,
                        idProjet:id_prj,
                        nProjet:nomprj,
                        ddate:ddat,
                        valider:val
                        } 
                        });
        },

        //fin bncharge

        clic_add_uplode_image(bon)
        {
            if(bon.is_img==1)
            {

                    this.isLoading=true;
                    this.$http.get('/getImageUrl/Facts/'+bon.img_name)
                    .then((result) => {


                            if(result.data.data!=null)
                            {
                                    this.itemFormImage.rep_url=result.data.data
                                    this.itemFormImage.url=null
                                    this.itemFormImage.id_bon=bon.id
                                    this.itemFormImage.img_name=bon.img_name
                                     this.itemFormImage.typ='Facts'
                                    this.$root.$emit('bv::show::modal', 'uploadimgmodal')
                            }
                            else
                            {
                                    this.itemFormImage.rep_url=null
                                    this.itemFormImage.url=null
                                    this.itemFormImage.id_bon=bon.id
                                    this.itemFormImage.img_name=null
                                    this.itemFormImage.typ='Facts'
                                    this.$root.$emit('bv::show::modal', 'uploadimgmodal')
                            }


                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

            }
            else
            {
                this.itemFormImage.url=null
                this.itemFormImage.rep_url=null
                this.itemFormImage.id_bon=bon.id
                this.itemFormImage.img_name=null
                this.itemFormImage.typ='Facts'
                this.$root.$emit('bv::show::modal', 'uploadimgmodal')
            }



        },

        edit_fact_four_click(item, index, button) {

        this.$confirm("Voulez-vous vraiment modifier cet élément ??")
        .then(() => {


                    //chager les fournisseurs:
                    this.isLoading=true;
                    this.$http.get('/fournissMinActif')
                    .then((result) => {
                                this.fournisseurs = result.data.data
                                this.isLoading=false;
                        
                                this.itemFacture.id=item.id
                                this.itemFacture.nom=item.nom

                                this.itemFacture.date=item.date
                                this.itemFacture.total_ttc=item.total_ttc,

                                this.itemFacture.description=item.description,
                                this.itemFacture.fournisseur_id=item.fournisseur_id


                                this.ModalEdit=true;
                                this.ModalType='Modifier'

                                this.$root.$emit('bv::show::modal', 'facturemodal', button)

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });



        })
        .catch(error=> {
        console.log(error)

        });


        },

        
        del_factur_four_click(itemo) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                .then(() => {


                this.isLoading=true;
                this.$http.delete('/projetLivraison/'+itemo.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.listFacture.findIndex(item => item.id === itemo.id)
                    this.listFacture.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

                })
                .catch(error=> {
                console.log(error)

                    });




        },

        handleSubmitFacture(){
               
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.itemFacture.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                    if(this.ModalEdit)
                            {
                                this.banquefacfourEdit()
                            }
                            else
                            {

                                this.itemsfacfourlisttore()
                            }    

                }


        },
        itemsfacfourlisttore()
        {


                        this.isLoading=true;

            this.$http.post('/projetLivraison',{

                    nom:this.itemFacture.nom,
                    date:this.itemFacture.date,
                    regler:0,
                    total_ttc:this.itemFacture.total_ttc,
                    description:this.itemFacture.description,
                    projet_id:this.idBon,
                    fournisseur_id:this.itemFacture.fournisseur_id,
                    user:this.user_name


                })
                .then(response => {

                    this.listFacture.unshift(response.data.data);

                    this.isLoading=false;
                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '


                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },



        banquefacfourEdit(){

                this.isLoading=true;

                this.$http.patch('/projetLivraison/'+this.itemFacture.id,{

                nom:this.itemFacture.nom,
                date:this.itemFacture.date,
                total_ttc:this.itemFacture.total_ttc,
                description:this.itemFacture.description,
                fournisseur_id:this.itemFacture.fournisseur_id,
                user:this.user_name


    })
    .then(response => {

        this.isLoading=false;
        console.log(response.data)
        this.$bvModal.hide('facturemodal')

        let index = this.listFacture.findIndex(item => item.id === this.itemFacture.id)
        this.listFacture.splice(index, 1,response.data.data)
        
        this.flashMessage.show({
        status: 'success',
        title: 'Données modifiées',
        message: 'Les données sont modifiées correctement'
        })


        })
        .catch(error=> {console.log(error)
        this.isLoading=false;


        this.flashMessage.show({
        status: 'error',
        title: 'Erreur De Modification',
        message: 'Impossible  de modifier les données .. Réessayez !!'
    })
    });

        },

//pa
        fetchFournisShowFacture() {

                    //chager les fournisseurs:
                    this.isLoading=true;
                    this.$http.get('/fournissMinActif')
                    .then((result) => {
                                this.fournisseurs = result.data.data
                                this.isLoading=false;
                                
                                this.itemFacture.description=this.nomBon
                                
                                this.$root.$emit('bv::show::modal', 'facturemodal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },

        del_contactclick(itm) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {


            this.isLoading=true;

            this.$http.delete('/projetContact/'+itm.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.listContact.findIndex(item => item.id === itm.id)


                   this.listContact.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });
                                })

                  //erreur de dialog              
                .catch(error=> {
                console.log(error)

                });




        },

        GetContactAndShow()
            {
                                this.isLoading=true;
                                this.$http.get('/contactOfProjet/'+this.idBon)
                                .then((result) => {
                                            this.listContact = result.data.data
                                            this.isLoading=false;

                                            this.$root.$emit('bv::show::modal', 'contactmodal')
                                })
                                .catch(error=> {
                                            console.log(error)
                                            this.isLoading=false;
                                            this.flashMessage.show({
                                            status: 'error',
                                            title: 'Erreur de Chargement',
                                            message: 'Impossible charger les données.. Actualisez et Réessayez'
                                            })
                                });  
        },

        handleSubmitContact() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.itemFormContact.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                                this.isLoading=true;

                                this.$http.post('/projetContact',{
                                nom:this.itemFormContact.nom,
                                tel:this.itemFormContact.tel,
                                email:this.itemFormContact.email,
                                note:this.itemFormContact.note,
                                projet_id:this.idBon

                                    })
                                    .then(response => {

                                        this.isLoading=false;
                                        this.listContact.unshift(response.data.data);

                                        //afficher message
                                        this.flashMessage.show({
                                        status: 'success',
                                        title: 'Données sauvegardées',
                                        message: 'Les données sont sauvegardées correctement '
                                    })


                                    })
                                    .catch(error=> {console.log(error)
                                    this.isLoading=false;


                                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur De Sauvegarde',
                                    message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                    })
                                    });


                }

        },

        edit_detail_bl(itm){
                                    //afficher les details:
                                    this.preparerDetail(
                                    itm.id,
                                    itm.nom,
                                    this.nClient,
                                    this.idBon,
                                    this.nomBon,
                                    itm.date,
                                    itm.valider
                                    )
        },

        del_bl_click(itemo) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {


            this.isLoading=true;

            this.$http.delete('/projetBls/'+itemo.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslistbl.findIndex(item => item.id === itemo.id)
                    this.itemslistbl.splice(index, 1)



                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

                                })
                                .catch(error=> {
                                console.log(error)

                                    });




        },

        edit_bl_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {


                    this.itemFormBon.id=item.id,
                    this.itemFormBon.nom=item.nom,
                    this.itemFormBon.date=item.date,
                    this.itemFormBon.valider=item.valider,
                    this.itemFormBon.regler=item.regler,
                    this.itemFormBon.total_ttc=item.total_ttc,
                    this.itemFormBon.description=item.description,
                    this.itemFormBon.client_id=item.client_id,
                    this.itemFormBon.projet_id=item.projet_id



                     //Activer la modification
                    this.isModification=true

                        //boninfos Champs disibl:
                        this.nom_dis=false,
                this.$root.$emit('bv::show::modal', 'blModal', button)
                })
                .catch(error=> {
                console.log(error)

                });

        },

        add_bl_click()
        {
                    this.itemFormBon.id="",
                    this.itemFormBon.nom="--",
                    this.itemFormBon.date=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    this.itemFormBon.valider=0,
                    this.itemFormBon.regler=0,
                    this.itemFormBon.total_ttc=0,
                    this.itemFormBon.description="",
                    this.itemFormBon.client_id=this.idClient,
                    this.itemFormBon.depot_id=this.idBon



                     //Activer la modification
                    this.isModification=false

                                        //boninfos Champs disibl:
                        this.nom_dis=true,

                    this.$root.$emit('bv::show::modal', 'blModal' )

        },
        //add bon
        handleSubmitBonCMD(){


                        
                        this.isSubmitted = true;

                        this.$v.$touch();
                        if (this.$v.itemFormBon.$invalid) {
                        this.flashMessage.show({
                        status: 'info',
                        title: 'Vérifiez les données et Reésayez !!',
                        })
                        }
                        else 
                        {


                            if(this.isModification)
                            {
                        
                            this.edit_bon()
                            }
                            else
                            {
                            
                            this.add_bon()
                            }
        

                        }


        },
        add_bon()
                {

                    this.isLoading=true;

                    this.$http.post('/projetBls',{

                            nom:this.itemFormBon.nom,
                            date:this.itemFormBon.date,
                            valider:0,
                            regler:0,
                            total_ttc:0,
                            description:this.itemFormBon.description,
                            projet_id:this.idBon,
                            client_id:this.idClient,
                            user:this.user_name


                        })
                        .then(response => {
                            //metre a jour id
                            this.itemFormBon.id=response.data.data.id,
                            this.itemFormBon.nom=response.data.data.nom,

                            //Activer la modification
                            this.isModification=true,


                            this.isLoading=false;
                            //afficher message
                            this.flashMessage.show({
                            status: 'success',
                            title: 'Données sauvegardées',
                            message: 'Les données sont sauvegardées correctement '


                        })
                            //afficher les details:
                            this.preparerDetail(
                            response.data.data.id,
                            response.data.data.nom,
                            this.nClient,
                            this.idBon,
                            this.nomBon,
                            response.data.data.date,
                            0
                            )

                        })
                        .catch(error=> {console.log(error)
                        this.isLoading=false;


                    this.flashMessage.show({
                        status: 'error',
                        title: 'Erreur De Sauvegarde',
                        message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                        })
                        });


        },
        edit_bon()
                {

                    this.isLoading=true;

                    this.$http.patch('/projetBls/'+this.itemFormBon.id,{

                            nom:this.itemFormBon.nom,
                            date:this.itemFormBon.date,
                            valider:this.itemFormBon.valider,
                            regler:this.itemFormBon.regler,
                            total_ttc:this.itemFormBon.total_ttc,
                            description:this.itemFormBon.description,
                            projet_id:this.itemFormBon.projet_id,
                            client_id:this.itemFormBon.client_id,
                            user:this.user_name
                        })
                        .then(response => {

                            this.isLoading=false;
                       

                    this.$bvModal.hide('blModal')

                    let index = this.itemslistbl.findIndex(item => item.id === response.data.data.id)
                    this.itemslistbl.splice(index, 1,response.data.data)


                            //afficher message
                            this.flashMessage.show({
                            status: 'success',
                            title: 'Données sauvegardées',
                            message: 'Les données sont sauvegardées correctement '
                        })



                        })
                        .catch(error=> {console.log(error)
                        this.isLoading=false;


                    this.flashMessage.show({
                        status: 'error',
                        title: 'Erreur De Sauvegarde',
                        message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                        })
                        });


        },

        preparerDetail(id_bon,nom,nom_fou,id_prj,nomprj,ddat,val)
        {

                        this.$router.push({ 
                        name: 'projetblDetail', 
                        params: { 
                        idBon: id_bon,
                        nomBon: nom,
                        nClient: nom_fou,
                        idProjet:id_prj,
                        nProjet:nomprj,
                        ddate:ddat,
                        valider:val
                        } 
                            });
        },


        //fin bon
        edit_detail_etap(itm)
        {

                                this.$router.push({ 
                                name: 'etapeProjetDetail', 
                                params: { 
                                idBon:this.idBon,
                                nomProjet: this.nomBon,

                                idEtape: itm.id,
                                nomEtape: itm.nom,

                                idClient: this.idClient,
                                nClient: this.nClient,

                                ddate_debut: itm.date_debut,
                                ddate_fin: itm.date_fin,
                                idDep:this.idDep,
                                etat:itm.etat
                                } 
                                });


        },

        addSorti_click() {

                            let mm= this.listEtapes.length +1

                            this.itemFormEtape.nom='Etape : '+mm
                            this.itemFormEtape.date_debut=new Date().toJSON().slice(0,10).replace(/-/g,'-')
                            this.itemFormEtape.date_fin=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                            this.itemFormEtape.etat=0

                            this.ModalEdit=false;
                            this.ModalType='Ajouter'

                            this.$root.$emit('bv::show::modal', 'sortiModal' )
        },

        edit_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {
                this.itemFormEtape.id=item.id
                this.itemFormEtape.nom=item.nom

                this.itemFormEtape.date_debut=item.date_debut
                this.itemFormEtape.date_fin=item.date_fin,

                this.itemFormEtape.description=item.description,
                this.itemFormEtape.etat=item.etat

                this.ModalEdit=true;
                this.ModalType='Modifier'

                this.$root.$emit('bv::show::modal', 'sortiModal', button)
                })
                .catch(error=> {
                console.log(error)

                });


        },

        itemslisttore(){

                            this.isLoading=true;

                            this.$http.post('/projetEtapes',{
                            nom:this.itemFormEtape.nom,
                            date_debut:this.itemFormEtape.date_debut,
                            date_fin:this.itemFormEtape.date_fin,

                            description:this.itemFormEtape.description,
                            etat:this.itemFormEtape.etat,
                            user:this.user_name,
                            projet_id:this.idBon

                                })
                                .then(response => {

                                    this.isLoading=false;
                                    this.listEtapes.unshift(response.data.data);

                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })
                                
                                this.$bvModal.hide('sortiModal')

                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });

        },
        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/projetEtapes/'+this.itemFormEtape.id,{
                            nom:this.itemFormEtape.nom,
                            date_debut:this.itemFormEtape.date_debut,
                            date_fin:this.itemFormEtape.date_fin,

                            description:this.itemFormEtape.description,
                            etat:this.itemFormEtape.etat,
                            user:this.user_name,
                            projet_id:this.idBon

                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data)
                    this.$bvModal.hide('sortiModal')

                    let index = this.listEtapes.findIndex(item => item.id === this.itemFormEtape.id)
                    this.listEtapes.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },

        handleSubmitEtape(){


              this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.itemFormEtape.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }    
                }

        },

        del_click(itemo) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {


            this.isLoading=true;
                this.$http.get('/suppEtapesProjAll/'+itemo.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.listEtapes.findIndex(item => item.id === itemo.id)
                    this.listEtapes.splice(index, 1)

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

                                })
                                .catch(error=> {
                                console.log(error)

                                    });




        },

        fetchInitprojetInfos(id_etap)
        {
                    this.isLoading=true;
                    this.$http.get('/getIntDetailProjet/'+id_etap)
                    .then((result) => {
                                this.listEtapes = result.data.data_etape
                                this.itemslistbl = result.data.data_bl
                                this.listFacture = result.data.data_factures
                                this.itemslistcharg = result.data.data_chargs

                                            

            this.etatProjet = result.data.data_proj.etat

            this.nomBon= result.data.data_proj.nom
            this.idClient= result.data.data_proj.client_id
            this.ddate=result.data.data_proj.date
            this.ddateLivraison=result.data.data_proj.date_livraison
            this.idDep=result.data.data_proj.depot_id
            this.etat=result.data.data_proj.etat
            this.is_projet_charg=true



                                this.isLoading=false;

                                //effacer le search:
                                this.serchArt=''
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });



        },
        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuProjet'
                    });
        },

        clickValiderBon(typ)
        {
        
                                this.$confirm("Voulez-vous vraiment modifier l'etat de ce projet??")
                                .then(() => {

                                    this.validerBon(this.idBon,typ)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });

        },
        validerBon(id_bn,ty)
        {

            this.isLoading=true;

            this.$http.get('/modifProjetEtat/'+id_bn+'/'+ty)
                .then(response => {


                this.etatProjet = response.data.data.etat

                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Bon valider',
                message: 'Les données sont validées correctement'
                })



                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Validation',
                message: 'Impossible  de valider les données .. Réessayez !!'
                })
                });

        },

      rowClassbl(item, type) {
        if (!item || type !== 'row') return
        if (item.valider === 1) return 'table-success'
      },     
       rowClassEtap(item, type) {
        if (!item || type !== 'row') return
        if (item.etat === 1) return 'table-success'
      }

        },
        components: {
                    Loading,
              uploadBonImg 
                     
        }


}
</script>

<style>
/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>