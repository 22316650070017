<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-secondary">
 <i class="fas fa-project-diagram fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-secondary mb-4">
 <h2 class="font-weight-lighter">Rapports Des Projets</h2>
</div>



<div  class=" row p-4">

            <!-- Earnings (Monthly) Card Example  -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_list">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Liste Des Projets</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


                        <!-- Earnings (Monthly) Card Example  -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_art">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Entrées / Sortie Par Article</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>



<div  class=" row p-4">

              <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_listfac">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Liste Des Produits Finis (Livrés)</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list  fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


</div>





  </div>
</template>

<script>
export default {
computed:{

},
 methods:{
       click_art()
      {
                      //goto first page: 
                    this.$router.push({ 
                        name: 'artEntreesSortiesRapp'

                    });
      },
      click_list()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'projetRapp'

                    });
      },
       click_listfac()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'artLivrBlProjetsRapp'

                    });
      },


 }
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>